<template>
  <div class="sb_right">
    <div class="sbr_left">
      <div class="sbrl_name">预览</div>
      <div class="sbrl_block">
        <img src="@/assets/sj_bj.png" alt="" class="sbrl_bj">
        <div class="simulation" style="z-index: 0;">
          <img :src="launch.bgiUrl || showimg" alt="" class="simulation_bj">
        </div>
        <div class="simulation">
          <div class="simulation_title">{{launch.showTitle ? row?.name : ''}}</div>
          <div class="simulation_desc">
            <div class="simulation_desc_block" style="white-space: pre-wrap;word-break: break-all;">{{launch.tips}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="sbr_right">
      <div class="sbrr_name">编辑</div>
      <div class="sbrr_block">
        <div class="sbrr_title">
          【启动页】<br />活动参与者进入的第一个页面，一般用于讲述活动背景，渲染活动氛围
        </div>
        <div class="sbrr_titl">背景图/音乐</div>
        <div class="sbrr_item">
          <div class="sbrri_name">选择模板图</div>
          <el-select v-model="launch.templateId" placeholder="请选择" style="width: 190px;" @change="templ">
            <el-option :label="item.name" :value="item.id" v-for="item in list" :key="item.id"></el-option>
          </el-select>
        </div>
        <div class="sbrr_item">
          <div class="sbrri_name">自定义上传背景图</div>
          <el-upload
            style="width: 71px"
            class="avatar-uploader"
            :action="api + '/api/io/upload/'"
            :show-file-list="false"
            :on-success="handleSucces"
            :disabled="launch.templateId > 0"
            :before-upload="beforeUpload"
            :headers="{'token': token}">
            <img v-if="launch.bgiUrl" :src="launch.bgiUrl" class="avatar" style="padding-top: -10px">
            <span v-else>
              <img src="@/assets/add2.png" alt="">
            </span>
          </el-upload>
          <div class="sbrr_itps">图片像素750*1624<br />图片格式建议为JPG</div>
        </div>
        <div class="sbrr_item">
          <div class="sbrri_name">上传启动视频</div>
          <el-upload
            style="width: 71px"
            class="avatar-uploader"
            :action="api + '/api/io/upload/'"
            :show-file-list="false"
            :on-success="handleSuccess"
            :on-progress="onprogress"
            v-loading="loading"
            element-loading-text="上传中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :before-upload="beforeUpload"
            :headers="{'token': token}">
            <img v-if="launch.bgvUrl" src="@/assets/wc.jpg" class="avatar" style="width: auto;">
            <span v-else>
              <img src="@/assets/add2.png" alt="">
            </span>
          </el-upload>
          <div class="sbrr_itps">视频建议MP4格式<br />时长建议1分钟内，小于50M</div>
        </div>
        <div class="sbrr_item">
          <div class="sbrri_name">上传背景音乐（可选）</div>
          <el-upload
            style="width: 71px"
            class="avatar-uploader"
            :action="api + '/api/io/upload/'"
            :show-file-list="false"
            :on-success="handleSucce"
            :on-progress="onprogress1"
            v-loading="loading1"
            element-loading-text="上传中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :before-upload="beforeUpload"
            :headers="{'token': token}">
            <span v-if="launch.bgmUrl">
              上传<br />
              成功
            </span>
            <span v-else>
              <img src="@/assets/music.png" alt="" style="height: 41px;">
            </span>
          </el-upload>
          <div class="sbrr_itps">建议为MP3格式</div>
        </div>
        <el-checkbox-group v-model="launch.showTitle" style="margin: 17px 0 0 37px;">
          <el-checkbox label="显示标题">显示标题</el-checkbox>
        </el-checkbox-group>
        <div class="sbrr_titl">启动文案</div>
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="launch.tips"
          class="sbrr_desc"
          @change="abc">
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
import { getLaunchByActivity, api, getTemplates } from '@/api/api'
import base64 from '@/utils/base64';
export default {
  name: 'StartupView',
  data() {
    return {
      token: new Date().getTime()+';'+base64.accumulation(new Date().getTime()),
      launch: {
        activityId: 0,
        bgiUrl: '',
        bgmName: '',
        bgmUrl: '',
        bgvUrl: '',
        createBy: '',
        createOn: '',
        id: 0,
        isDeleted: 0,
        modifyBy: '',
        modifyOn: '',
        remark: '',
        showTitle: 1,
        templateId: 30,
        tips: ''
      },
      api: api,
      list: [],
      showimg: '',
      loading: false,
      loading1: false,
      row: null
    }
  },
  methods: {
    beforeUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        this.$message.error('上传大小不能超过 50MB!');
      }
      return isLt2M;
    },
    abc () {
    },
    onprogress1 () {
      this.loading1 = true
    },
    onprogress () {
      this.loading = true
    },
    templ () {
      this.launch.bgiUrl = ''
      if (this.launch.templateId) {
        this.list.forEach((res)=>{
          if (res.id === this.launch.templateId) {
            this.showimg = res.imageUrl
          }
        })
      } else {
        this.showimg = ''
      }
    },
    handleSuccess (res) {
      this.launch.bgvUrl = res
      this.loading = false
      this.$message.success('上传成功')
    },
    handleSucces (res) {
      this.launch.bgiUrl = res
      this.$message.success('上传成功')
    },
    handleSucce (res) {
      this.launch.bgmUrl = res
      this.loading1 = false
      this.$message.success('上传成功')
    },
    async getTemplates() {
      const data = {
        templateType: 2
      }
      const res = await getTemplates(data)
      this.list = [{id: 0,name: "自定义"},...res.data]
    },
    async getinit (row) {
      await this.getTemplates()
      let datarow = {
        activityId: 0,
        bgiUrl: '',
        bgmName: '',
        bgmUrl: '',
        bgvUrl: '',
        createBy: '',
        createOn: '',
        id: 0,
        isDeleted: 0,
        modifyBy: '',
        modifyOn: '',
        remark: '',
        showTitle: 1,
        templateId: 30,
        tips: ''
      }
      this.showimg = ''
      this.row = row
      if (row.id > 0) {
        const data = {
          activityId: row.id
        }
        const res = await getLaunchByActivity(data)
        if (res.data) {
          datarow = res.data
        }
      } 
      if (datarow.showTitle == 1) {
        datarow.showTitle = true
      } else {
        datarow.showTitle = false
      }
      this.launch = { ...datarow }
      if (this.launch.templateId) {
        this.templ()
      }
    }
  }
} 
</script>
<style lang="scss" scoped>
@import './index.scss'
</style>
  