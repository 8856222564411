<template>
  <div class="index">
    <div class="block">
      <div class="search" style="width: 1200px; margin: 0 auto;">
        <div class="activity_id">{{id}}</div>
        <div class="activity_name">{{name}}</div>
      </div>
      <div class="data_block">
        <div class="data">
          <div class="data_name">人数</div>
          <div class="data_sum">{{ info?.totalPeople || 0 }}</div>
        </div>
        <div class="data">
          <div class="data_name">组数</div>
          <div class="data_sum">{{ info?.totalTeam || 0 }}</div>
        </div>
        <div class="data">
          <div class="data_name">总完成数</div>
          <div class="data_sum">{{ info?.totalComplete || 0 }}</div>
        </div>
        <div class="data">
          <div class="data_name">平均完成时长</div>
          <div class="data_sum">{{ info?.advTime || 0 }}</div>
        </div>
        <div class="data">
          <div class="data_name">任务数</div>
          <div class="data_sum">{{ info?.totalTask || 0 }}</div>
        </div>
        <div class="data">
          <div class="data_name">视频数</div>
          <div class="data_sum">{{ info?.totalVideo || 0 }}</div>
        </div>
        <div class="data">
          <div class="data_name">弹幕数</div>
          <div class="data_sum">{{ info?.totalBullet || 0 }}</div>
        </div>
        <div class="data">
          <div class="data_name">点赞数</div>
          <div class="data_sum">{{ info?.totalLike || 0 }}</div>
        </div>
      </div>
      <div class="tab1">
        <div class="tabselect">
          <div :class="zxindex === 0 ? 'tablist active' : 'tablist'" @click="getop(0)">图片</div>
          <div :class="zxindex === 1 ? 'tablist active' : 'tablist'" @click="getop(1)">视频</div>
          <div :class="zxindex === 2 ? 'tablist active' : 'tablist'" @click="getop(2)">弹幕</div>
          <div :class="zxindex === 3 ? 'tablist active' : 'tablist'" @click="getop(3)">排行榜</div>
        </div>
        <el-button @click="activeexport" v-if="zxindex === 3">导出</el-button>
      </div>
      <div class="title" v-if="(zxindex === 0 || zxindex === 1)">
        <div class="title_left">
          <div :class="titleindex === index ? 'active' : ''" v-for="(i,index) in titlelist" :key="index" @click="getitle(index)">{{i.taskName}}</div>
        </div>
      </div>
      <div class="list" v-if="(zxindex === 0 || zxindex === 1)">
        <div class="item" v-for="item in tablist" :key="item.id">
          <img :src="item.answer" alt="" class="item_img" v-if="item.answerMode == 4" @click="look(1, item.answer)">
          <video :src="item.answer" alt="" class="item_img" v-if="item.answerMode == 3" @click="look(2, item.answer)" />
        </div>
      </div>
      <div class="block" v-if="zxindex === 2">
        <el-table :data="tablist1" style="width: 100%;margin-top: 18px;">
          <el-table-column prop="comment" show-overflow-tooltip label="弹幕内容" align="center">
          </el-table-column>
          <el-table-column prop="createBy" show-overflow-tooltip label="用户昵称" align="center">
          </el-table-column>
        </el-table>
        <!-- <div class="page">
          <el-pagination
            small
            layout="total, prev, pager, next, jumper"
            :total="total"
            :current-page="page1"
            @current-change="gotopage">
          </el-pagination>
        </div> -->
      </div>
      <div class="block" v-if="zxindex === 3">
        <el-table :data="userlist" style="width: 100%;margin-top: 18px;">
          <el-table-column prop="ranking" show-overflow-tooltip label="名次" align="center">
          </el-table-column>
          <el-table-column prop="name" show-overflow-tooltip label="组名" align="center">
          </el-table-column>
          <el-table-column prop="percentage" show-overflow-tooltip label="完成度" align="center">
          </el-table-column>
          <el-table-column prop="time" show-overflow-tooltip label="用时" align="center">
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            background
            layout="total, prev, pager, next, jumper"
            :total="usertotal"
            :current-page="userpage"
            @current-change="gotopage1">
          </el-pagination>
        </div>
      </div>
    </div>
    <preview :isimg="isimg" :url="url" :isvideo="isvideo" ref="preview"></preview>
  </div>
</template>

<script>
import { statisticsActivity, getTaskAuditAnswers, getActivityTasks, getPageRankingList, getActivityBullets, teamexport } from '@/api/api'
import preview from '@/components/preview/index.vue'
export default {
  name: 'ExamineView',
  components: {
    preview
  },
  data() {
    return {
      zxindex: 0,
      input: '',
      titlelist: [],
      titleindex: 0,
      listindex: [],
      id: '',
      name: '',
      taskId: 0,
      info: null,
      tablist: [],
      page: 1,
      idlist: [],
      total: 0,
      tablist1: [],
      page1: 1,
      usertotal: 0,
      userpage: 1,
      userlist: [],
      isimg: false,
      isvideo: false,
      url: ''
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    }
  },
  methods: {
    async activeexport () {
      const data = {
        activityId: this.id,
        pageSize: 10,
        pageIndex:this.userpage
      }
      const res = await teamexport(data)
      let url = res.data
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = url
      link.setAttribute('download', '排行榜.xls')
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    look (type, url) {
      if (type == 1) {
        this.isimg = true
        this.isvideo = false
      } else {
        this.isvideo = true
        this.isimg = false
      }
      this.url = url
      this.$refs['preview'].show = true
    },
    gotopage (e) {
      this.page1 = e
      this.getActivityBullets()
    },
    gotopage1 (e) {
      this.userpage = e
      this.getPageRankingList()
    },
    async getPageRankingList () {
      const data = {
        activityId: this.id,
        pageSize: 10,
        pageIndex:this.userpage
      }
      const res = await getPageRankingList(data)
      this.userlist = res.data.list
      this.usertotal = res.data.total
    },
    load () {
      this.page++
      this.getTeamTaskAuditAnswers()
    },
    getinit (id, name = '活动名称') {
      this.id = id
      this.name = name
      this.taskId = 0
      this.zxindex = 0
      this.getActivityTasks()
      this.statisticsActivity()
      this.getTeamTaskAuditAnswers()
    },
    async getActivityTasks () {
      const data = {
        activityId: this.id
      }
      const res = await getActivityTasks(data)
      let list = []
      res.data.forEach((res) => {
        if (this.zxindex == 1 && res.answerMode == 3) {
          list.push(res)
        } else if (this.zxindex == 0 && res.answerMode == 4) {
          list.push(res)
        }
      })
      this.titlelist = [{taskName: '全部任务',id: 0},...list]
    },
    async getTeamTaskAuditAnswers () {
      const data = {
        activityId: this.id,
        answerMode: this.zxindex == 1 ? 3 : 4,
        pageIndex: this.page,
        pageSize: 10,
        taskId: this.taskId,
        isAudited: 2
      }
      const res = await getTaskAuditAnswers(data)
      this.tablist = [...this.tablist, ...res.data.list]
    },
    async statisticsActivity () {
      const data = {
        activityId: this.id
      }
      const res = await statisticsActivity(data)
      this.info = res.data
    },
    getop (index) {
      if (index === this.zxindex) {
        return false
      }
      this.zxindex = index
      if (index === 0 || index === 1) {
        this.page = 1
        this.idlist = []
        this.tablist = []
        this.getActivityTasks()
        this.getTeamTaskAuditAnswers()
      }
      if (index === 2) {
        this.page1 = 1
        this.getActivityBullets()
      }
      if (index === 3) {
        this.userpage = 1
        this.getPageRankingList()
      }
    },
    async getActivityBullets () {
      const data = {
        activityId: this.id,
        pageIndex: this.page1,
        pageSize: 10
      }
      const res = await getActivityBullets(data)
      this.tablist1 = res.data
    },
    getitle (index) {
      if (index === this.titleindex) {
        return false
      }
      this.titleindex = index
      this.taskId = this.titlelist[index].id
      this.tablist = []
      this.idlist = []
      this.page = 1
      this.getTeamTaskAuditAnswers()
    },
    getlistshow (item, index) {
      if (this.listindex.indexOf(index) !== -1) {
        return false
      }
      this.listindex.push(index)
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss'
</style>
