<template>
  <div class="index">
    <HeaderView :xzindex="1"></HeaderView>
    <div class="block" v-if="!taskshow">
    <div class="left">
      <div class="left_top">
        <div>预览</div>
        <div @click="copy">复制到我的任务</div>
      </div>
      <div class="sbrl_block">
        <img src="@/assets/sj_bj1.png" alt="" class="sbrl_bj">
        <div class="simulation" style="z-index: 0;" v-if="task?.taskName">
          <img v-if="task?.bgiUrl" :src="task?.bgiUrl" alt="" class="simulation_bj">
        </div>
        <div class="simulation" v-if="task?.taskName">
          <div class="task_back">
            <img src="@/assets/back.png" alt="" class="back">
          </div>
          <div class="task_title" v-if="task?.showTitle">活动标题</div>
          <div class="task_team">
            <div class="team_top">
              <img class="team_img" src="https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg" alt="">
              <div class="task_teamname">队伍名称</div>
            </div>
            <div class="team_center">
              <img src="@/assets/linel.png" alt="" class="linel">
              <div class="team_center_name">共10人</div>
              <img src="@/assets/line.png" alt="" class="linel">
            </div>
            <div class="team_bottom">
              <img class="team_bottom_img" v-for="i in 10" :key="i" src="https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg" alt="">
            </div>
          </div>
          <div class="task_block">
            <div class="task_block_top">
              {{task?.taskName}}
            </div>
            <div class="task_block_center">
              <div class="ch_block" v-if="task?.tipsContent">
                <img src="https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg" class="ch_left" />
                <img src="@/assets/horn.png" class="horn" />
                <div class="ch_bloc">
                  {{task?.tipsContent}}
                </div>
              </div>
              <div class="ch_block" v-if="task?.tipsIurl">
                <img src="https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg" class="ch_left" />
                <img src="@/assets/horn.png" class="horn" />
                <div class="ch_bloc">
                  <img :src="task?.tipsIurl" class="horn1" />
                </div>
              </div>
              <div class="ch_block" v-if="task?.tipsVurl">
                <img src="https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg" class="ch_left" />
                <img src="@/assets/horn.png" class="horn" />
                <div class="ch_bloc">
                  <video :src="task?.tipsVurl" class="horn3"></video>
                </div>
              </div>
              <div class="ch_block" v-if="task?.pass">
                <img src="https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg" class="ch_left" />
                <img src="@/assets/horn.png" class="horn" />
                <div class="ch_bloc">
                  {{task?.pass}}
                </div>
              </div>
              <div class="ch_block" v-if="task?.passImgUrl">
                <img src="https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg" class="ch_left" />
                <img src="@/assets/horn.png" class="horn" />
                <div class="ch_bloc">
                  <img :src="task?.passImgUrl" class="horn1" />
                </div>
              </div>
              <div class="ch_block" v-if="task?.passVideoUrl">
                <img src="https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg" class="ch_left" />
                <img src="@/assets/horn.png" class="horn" />
                <div class="ch_bloc">
                  <video :src="task?.passVideoUrl" class="horn3"></video>
                </div>
              </div>
            </div>
            <div class="task_block_bottom">
              <div class="task_block_bottom_left">
                {{task?.answerMode == 1 ? '输入答案' : ''}}
                {{task?.answerMode == 3 ? '上传照片' : ''}}
                {{task?.answerMode == 4 ? '上传视频' : ''}}
              </div>
              <div class="task_block_bottom_right" v-if="task?.answerMode == 1">发送</div>
              <img src="@/assets/update.png" v-if="task?.answerMode == 4 || task?.answerMode == 3" alt="" class="update">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="right_top">
        <div style="width: 400px;">
          <el-input v-model="input" class="rt_input" placeholder="输入任务关键词" suffix-icon="el-icon-search" @change="inputsearch"></el-input>
        </div>
        <div class="rt_right" @click="addtask" v-if="user.isAdmin == 1">
          <img src="@/assets/add.png" alt="">新建任务
        </div>
      </div>
      <div class="list" v-infinite-scroll="load">
        <div :class="i.id == xzid ? 'item active' : 'item'" v-for="i in list" :key="i.id" @click="select(i)">
          <div class="title">{{i.taskName}}</div>
          <div class="desc">{{i.remark}}</div>
          <div class="label">
            <div class="litem" v-for="(it, index) in i.label" :key="index">{{it}}</div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div class="block">
      <task v-show="taskshow" :fabu="true" ref="task"></task>
    </div>
  </div>
</template>

<script>
import { getTaskStorestore, copyTask1 } from '@/api/api'
import HeaderView from '@/components/header/HeaderView.vue'
import task from '@/components/task/index.vue'
export default {
  name: 'TaskView',
  components: {
    HeaderView,
    task
  },
  data() {
    return {
      input: '',
      taskshow: false,
      list: [],
      xzid: '',
      page: 0,
      task: {
        activityId: 0,
        adminId: 0,
        answer: '',
        answerMode: 0,
        bgiUrl: '',
        createBy: '',
        error: '',
        id: 0,
        modifyBy: '',
        pass: '',
        passMode: 0,
        position: '',
        remark: '',
        showTitle: 0,
        status: 0,
        taskName: '',
        tipsContent: '',
        tipsIurl: '',
        tipsVurl: '',
        passImgUrl: '',
        passVideoUrl: ''
      }
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    }
  },
  methods: {
    copy () {
      if (!this.xzid) {
        this.$message.error('请选择任务')
        return false
      }
      this.$prompt('请输入新名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: '',
        inputErrorMessage: ''
      }).then(async ({ value }) => {
        const data = {
          adminId: this.user.adminId,
          newTaskName: value,
          oriTaskId: this.xzid
        }
        const res = await copyTask1(data)
        if (res.data) {
          this.$message({
            type: 'success',
            message: '复制成功!'
          });
        }
      }).catch(() => {
             
      });
    },
    select (row) {
      if (row.id == this.xzid) {
        this.xzid = ''
        this.task = {
          activityId: 0,
          adminId: 0,
          answer: '',
          answerMode: 0,
          bgiUrl: '',
          createBy: '',
          error: '',
          id: 0,
          modifyBy: '',
          pass: '',
          passMode: 0,
          position: '',
          remark: '',
          showTitle: 0,
          status: 0,
          taskName: '',
          tipsContent: '',
          tipsIurl: '',
          tipsVurl: '',
          passImgUrl: '',
          passVideoUrl: ''
        }
      } else {
        this.xzid = row.id
        this.task = row
      }
    },
    inputsearch () {
      this.page = 1
      this.list = []
      this.getTaskStore()
    },
    addtask () {
      let that = this
      that.taskshow = true
      that.$nextTick(() => {
        that.$refs.task.getinit()
      })
    },
    async getTaskStore () {
      const data = {
        taskName: this.input,
        pageIndex: this.page,
        pageSize: 20
      }
      const res = await getTaskStorestore(data)
      res.data.list.forEach((res) => {
        if (res.label) {
          res.label = res.label.split(',')
        }
      })
      this.list = [ ...this.list,...res.data.list]
      this.xzid = ''
    },
    load () {
      this.page++
      this.getTaskStore()
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss'
</style>
