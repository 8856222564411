import { render, staticRenderFns } from "./rule.vue?vue&type=template&id=c8507c9c&scoped=true&"
import script from "./rule.vue?vue&type=script&lang=js&"
export * from "./rule.vue?vue&type=script&lang=js&"
import style0 from "./rule.vue?vue&type=style&index=0&id=c8507c9c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c8507c9c",
  null
  
)

export default component.exports