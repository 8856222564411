import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// eslint-disable-next-line no-unused-vars
import { preventReClick } from '@/utils/directive'
Vue.use(ElementUI);
Vue.prototype.$message = function(msg){
  return ElementUI.Message({
    message: msg,
    duration: 2000
  })
}
//分别对success、warning和error等样式进行设置
Vue.prototype.$message.success = function (msg) {
  return ElementUI.Message.success({
    message: msg,
    duration: 2000
  })
}
Vue.prototype.$message.warning = function (msg) {
  return ElementUI.Message.warning({
    message: msg,
    duration: 2000
  })
}
Vue.prototype.$message.error = function (msg) {
  return ElementUI.Message.error({
    message: msg,
    duration: 2000
  })
}

Vue.config.productionTip = false

router.beforeEach(async (to, from, next) => {
  await store.dispatch('getInfo')
  if (to.meta.requireAuth) {
    if (localStorage.getItem('user')) {
      next()
    } else {
      ElementUI.Message.error('请先登录!')
      next({
        path: '/'
      })
    }
  } else {
    next()
  }
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
  