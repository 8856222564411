<template>
  <div class="sb_right">
    <div class="sbr_left">
      <div class="sbrl_name">预览</div>
      <div class="sbrl_block">
        <img src="@/assets/sj_bj.png" alt="" class="sbrl_bj">
        <div class="simulation" style="z-index: 0;">
          <img :src="rule.imgUrl" alt="" class="simulation_bj">
        </div>
        <div class="simulation">
          <div class="simulation_title">活动规则</div>
          <div class="simulation_desc">
            <div class="simulation_desc_block" style="white-space: pre-wrap;word-break: break-all;">{{rule.tips}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="sbr_right">
      <div class="sbrr_name">编辑</div>
      <div class="sbrr_block">
        <div class="sbrr_title">
          【活动规则】<br />尽量清晰地说明参与方式，让参与者能顺场地参与活动并尽量减少争议
        </div>
        <div class="sbrr_titl" style="margin-top: 39px">活动规则</div>
        <div class="sbrr_item" style="margin-top: 43px">
          <div class="sbrri_name">自定义上传规则说明</div>
          <el-upload
            style="width: 71px"
            class="avatar-uploader"
            :action="api + '/api/io/upload/'"
            :show-file-list="false"
            :on-success="handleSucces"
            :before-upload="beforeUpload"
            :headers="{'token': token}">
            <img v-if="rule.imgUrl" :src="rule.imgUrl" class="avatar" style="padding-top: -10px">
            <span v-else>
              <img src="@/assets/add2.png" alt="">
            </span>
          </el-upload>
          <div class="sbrr_itps">图片像素750*1624<br />图片格式建议为JPG</div>
        </div>
        <div class="sbrr_titl" style="marign-top: 55px">规则文案</div>
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="rule.tips"
          class="sbrr_desc1">
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
import { getActivityRuleByActivityId, api } from '@/api/api'
import base64 from '@/utils/base64';
export default {
  name: 'StartupView',
  data() {
    return {
      token: new Date().getTime()+';'+base64.accumulation(new Date().getTime()),
      rule: {
        activityId: 0,
        createBy: '',
        id: 0,
        imgUrl: '',
        modifyBy: '',
        remark: '',
        tips: ''
      },
      api: api
    }
  },
  methods: {
    beforeUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        this.$message.error('上传大小不能超过 50MB!');
      }
      return isLt2M;
    },
    handleSucces (res) {
      this.rule.imgUrl = res
      this.$message.success('上传成功')
    },
    async getinit (row, img = '') {
      let datarow  = {
        activityId: 0,
        createBy: '',
        id: 0,
        imgUrl: img,
        modifyBy: '',
        remark: '',
        tips: ''
      }
      if (row.id) {
        const data = {
          activityId: row.id
        }
        const res = await getActivityRuleByActivityId(data)
        if (res.data) {
          datarow = res.data
        }
      }
      this.rule = { ...datarow }
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss'
</style>
