<template>
  <div class="preview" v-if="((isimg || isvideo) && show)" @click="(show = false)">
    <img :src="url" alt="" v-if="isimg" class="preview_block" @click.stop="(show = true)">
    <video :src="url" v-if="isvideo" class="preview_block" controls @click.stop="(show = true)"></video>
  </div>
</template>

<script>
export default {
  name: 'PreviewView',
  props: {
    isimg: {
      type: Boolean,
      default: false
    },
    isvideo: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      default: ''
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    }
  },
  data() {
    return {
      show: true
    }
  },
  methods: {
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss'
</style>
