<template>
  <div class="sb_right">
    <div class="sbr_left">
      <div class="sbrl_name">预览</div>
      <div class="sbrl_block">
        <img src="@/assets/sj_bj.png" alt="" class="sbrl_bj">
        <div class="simulation" style="z-index: 0;">
          <img :src="task.bgiUrl" alt="" class="simulation_bj">
        </div>
        <div class="simulation">
          <div class="task_back">
            <img src="@/assets/back.png" alt="" class="back">
          </div>
          <div class="task_title">{{ task.showTitle ? row?.name : ''}}</div>
          <div class="task_team" v-if="row?.playMode == 2">
            <div class="team_top">
              <img class="team_img" src="https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg" alt="">
              <div class="task_teamname">队伍名称</div>
            </div>
            <div class="team_center">
              <img src="@/assets/linel.png" alt="" class="linel">
              <div class="team_center_name">共{{row?.playMode ? row.playMode == 2 ? row.teamMaxNum > 0 ? row.teamMaxNum : 10 : 10 : 10}}人</div>
              <img src="@/assets/line.png" alt="" class="linel">
            </div>
            <div class="team_bottom">
              <img class="team_bottom_img" v-for="i in row?.playMode ? row.playMode == 2 ? row.teamMaxNum > 0 ? row.teamMaxNum : 10 : 10 : 10" :key="i" src="https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg" alt="">
            </div>
          </div>
          <div class="task_block">
            <div class="task_block_top">
              {{task.taskName}}
            </div>
            <div class="task_block_center">
              <div class="ch_block" v-if="task.tipsContent">
                <img :src="row?.imageUrl || 'https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg'" class="ch_left" />
                <img src="@/assets/horn.png" class="horn" />
                <div class="ch_bloc" style="white-space: pre-wrap;word-break: break-all;text-align: left;">{{task.tipsContent}}</div>
              </div>
              <div class="ch_block" v-if="task.tipsIurl">
                <img :src="row?.imageUrl || 'https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg'" class="ch_left" />
                <img src="@/assets/horn.png" class="horn" />
                <div class="ch_bloc">
                  <img :src="task.tipsIurl" class="horn1" />
                </div>
              </div>
              <div class="ch_block" v-if="task.tipsVurl">
                <img :src="row?.imageUrl || 'https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg'" class="ch_left" />
                <img src="@/assets/horn.png" class="horn" />
                <div class="ch_bloc">
                  <video :src="task.tipsVurl" class="horn3"></video>
                </div>
              </div>
              <div class="ch_block" v-if="task.pass">
                <img :src="row?.imageUrl || 'https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg'" class="ch_left" />
                <img src="@/assets/horn.png" class="horn" />
                <div class="ch_bloc" style="white-space: pre-wrap;word-break: break-all;text-align: left;">{{task.pass}}</div>
              </div>
              <div class="ch_block" v-if="task.passImgUrl">
                <img :src="row?.imageUrl || 'https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg'" class="ch_left" />
                <img src="@/assets/horn.png" class="horn" />
                <div class="ch_bloc">
                  <img :src="task.passImgUrl" class="horn1" />
                </div>
              </div>
              <div class="ch_block" v-if="task.passVideoUrl">
                <img :src="row?.imageUrl || 'https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg'" class="ch_left" />
                <img src="@/assets/horn.png" class="horn" />
                <div class="ch_bloc">
                  <video :src="task.passVideoUrl" class="horn3"></video>
                </div>
              </div>
            </div>
            <div class="task_block_bottom">
              <div class="task_block_bottom_left">
                {{mode ? '输入答案' : ''}}
                {{mode2 ? '上传照片' : ''}}
                {{mode3 ? '上传视频' : ''}}
              </div>
              <div class="task_block_bottom_right" v-if="mode">发送</div>
              <img src="@/assets/update.png" v-if="mode2 || mode3" alt="" class="update">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sbr_right">
      <div class="sbrr_name">编辑</div>
      <div class="sbrr_block">
        <div class="sbrr_title">
          【任务页】<br />完整描述活动参与者需要完成的事项
        </div>
        <el-checkbox-group v-model="task.showTitle" style="margin: 17px 0 0 37px;">
          <el-checkbox label="显示标题">显示标题</el-checkbox>
        </el-checkbox-group>
        <div class="sbrr_item" style="margin: 10px auto 0;">
          <div class="sbrri_name">自定义上传背景图</div>
          <el-upload
            style="width: 71px"
            class="avatar-uploader"
            :action="api + '/api/io/upload/'"
            :show-file-list="false"
            :on-success="handleSucces"
            :before-upload="beforeUpload"
            :headers="{'token': token}">
            <img v-if="task.bgiUrl" :src="task.bgiUrl" class="avatar" style="padding-top: -10px">
            <span v-else>
              <img src="@/assets/add2.png" alt="">
            </span>
          </el-upload>
          <div class="sbrr_itps">图片像素750*1624<br />图片格式建议为JPG</div>
        </div>
        <div class="sbrr_item" style="margin: 16px auto 0;">
          <div class="sbrri_name">任务名称</div>
          <el-input v-model="task.taskName" class="sbrr_input" placeholder=""></el-input>
        </div>
        <div class="sbrr_item" style="margin: 16px auto 0;">
          <div class="sbrri_name">任务提示</div>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="task.tipsContent"
            class="sbrr_desc4">
          </el-input>
        </div>
        <div class="sbrr_item" style="margin: 9px auto 0;">
          <div style="opacity: 0;" class="sbrri_name"></div>
          <el-upload
            style="width: 71px"
            class="avatar-uploader"
            :action="api + '/api/io/upload/'"
            :show-file-list="false"
            :on-success="handleSucces1"
            :before-upload="beforeUpload"
            :headers="{'token': token}">
            <img v-if="task.tipsIurl" :src="task.tipsIurl" class="avatar" style="padding-top: -10px">
            <span v-else>
              <img src="@/assets/add2.png" alt="">
            </span>
          </el-upload>
          <div class="sbrr_itps">添加图片<br />图片格式为JPG</div>
        </div>
        <div class="sbrr_item" style="margin: 13px auto 0;">
          <div style="opacity: 0;" class="sbrri_name"></div>
          <el-upload
            style="width: 71px"
            class="avatar-uploader"
            :action="api + '/api/io/upload/'"
            :show-file-list="false"
            :on-success="handleSucces2"
            :on-progress="onprogress"
            v-loading="loading"
            element-loading-text="上传中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :before-upload="beforeUpload"
            :headers="{'token': token}">
            <img v-if="task.tipsVurl" src="@/assets/wc.jpg" class="avatar" style="width: auto;">
            <span v-else>
              <img src="@/assets/add2.png" alt="">
            </span>
          </el-upload>
          <div class="sbrr_itps">添加视频<br />视频格式为MP4，最大50M</div>
        </div>
        <div class="sbrr_item">
          <div class="sbrri_name">任务判定<div class="tips">(文本判定:多个答案用;分割)</div></div>
          <div class="sbrri_right">
            <el-checkbox-group v-model="mode" @change="pdclik">
              <el-checkbox label="1">文本判定</el-checkbox>
            </el-checkbox-group>
            <el-checkbox-group v-model="mode1" @change="pdclik1">
              <el-checkbox label="2">二维码扫码</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="sbrr_item" style="margin: 8px auto 0;">
          <div class="sbrri_name" style="opacity: 0;"></div>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="task.answer"
            class="sbrr_desc4">
          </el-input>
        </div>
        <div class="sbrr_item" style="margin: 8px auto 0;">
          <div class="sbrri_name" style="opacity: 0;"></div>
          <div class="sbrri_right">
            <el-checkbox-group v-model="mode2" @change="pdclik2">
              <el-checkbox label="3">照片审核</el-checkbox>
            </el-checkbox-group>
            <el-checkbox-group v-model="mode3" @change="pdclik3">
              <el-checkbox label="4">视频审核</el-checkbox>
            </el-checkbox-group>
          </div>
        </div>
        <div class="sbrr_item" style="margin: 16px auto 0;">
          <div class="sbrri_name">通过反馈</div>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="task.pass"
            class="sbrr_desc4"
            @input="passcli">
          </el-input>
        </div>
        <div class="sbrr_item" style="margin: 9px auto 0;">
          <div style="opacity: 0;" class="sbrri_name">通过反馈</div>
          <el-upload
            style="width: 71px"
            class="avatar-uploader"
            :action="api + '/api/io/upload/'"
            :show-file-list="false"
            :on-success="handleSucces3"
            :before-upload="beforeUpload"
            :headers="{'token': token}">
            <img v-if="task.passImgUrl" :src="task.passImgUrl" class="avatar" style="padding-top: -10px">
            <span v-else>
              <img src="@/assets/add2.png" alt="">
            </span>
          </el-upload>
          <div class="sbrr_itps">添加图片<br />图片格式为JPG</div>
        </div>
        <div class="sbrr_item" style="margin: 13px auto 0;">
          <div style="opacity: 0;" class="sbrri_name">通过反馈</div>
          <el-upload
            style="width: 71px"
            class="avatar-uploader"
            :action="api + '/api/io/upload/'"
            :show-file-list="false"
            :on-success="handleSucces4"
            :on-progress="onprogress1"
            v-loading="loading1"
            element-loading-text="上传中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :before-upload="beforeUpload"
            :headers="{'token': token}">
            <img v-if="task.passVideoUrl" src="@/assets/wc.jpg" class="avatar" style="width: auto;">
            <span v-else>
              <img src="@/assets/add2.png" alt="">
            </span>
          </el-upload>
          <div class="sbrr_itps">添加视频<br />视频格式为MP4，最大50M</div>
        </div>
        <div class="sbrr_item" style="margin: 16px auto 0;">
          <div class="sbrri_name">错误反馈</div>
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入内容"
            v-model="task.error"
            class="sbrr_desc4">
          </el-input>
        </div>
      </div>
    </div>
    <preview :isimg="isimg" :url="url" :isvideo="isvideo" ref="preview"></preview>
  </div>
</template>

<script>
import { getActivityTask, api } from '@/api/api'
import preview from '@/components/preview/index.vue'
import base64 from '@/utils/base64';
export default {
  name: 'StartupView',
  components: {
    preview
  },
  data() {
    return {
      token: new Date().getTime()+';'+base64.accumulation(new Date().getTime()),
      task: {
        activityId: 0,
        adminId: 0,
        answer: '',
        answerMode: 0,
        bgiUrl: '',
        createBy: '',
        error: '',
        id: 0,
        modifyBy: '',
        pass: '',
        passMode: 0,
        position: '',
        remark: '',
        showTitle: 1,
        status: 0,
        taskName: '',
        tipsContent: '',
        tipsIurl: '',
        tipsVurl: '',
        passImgUrl: '',
        passVideoUrl: ''
      },
      mode: false,
      mode1: false,
      mode2: false,
      mode3: false,
      api: api,
      loading: false,
      loading1: false,
      row: null,
      isvideo: false,
      isimg: false,
      url: ''
    }
  },
  methods: {
    look (type, url) {
      if (type == 1) {
        this.isimg = true
        this.isvideo = false
      } else {
        this.isvideo = true
        this.isimg = false
      }
      this.url = url
      this.$refs['preview'].show = true
    },
    beforeUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        this.$message.error('上传大小不能超过 50MB!');
      }
      return isLt2M;
    },
    onprogress () {
      this.loading = true
    },
    onprogress1 () {
      this.loading1 = true
    },
    pdclik (e) {
      this.task.answerMode = 0
      this.mode = this.mode1 = this.mode2 = this.mode3 = false
      if (e) {
        this.mode = true
        this.task.answerMode = 1
      }
    },
    pdclik1 (e) {
      this.task.answerMode = 0
      this.mode = this.mode1 = this.mode2 = this.mode3 = false
      if (e) {
        this.mode1 = true
        this.task.answerMode = 2
      }
    },
    pdclik2 (e) {
      this.task.answerMode = 0
      this.mode = this.mode1 = this.mode2 = this.mode3 = false
      if (e) {
        this.mode2 = true
        this.task.answerMode = 4
      }
    },
    pdclik3 (e) {
      this.task.answerMode = 0
      this.mode = this.mode1 = this.mode2 = this.mode3 = false
      if (e) {
        this.mode3 = true
        this.task.answerMode = 3
      }
    },
    handleSucces (res) {
      this.task.bgiUrl = res
      this.$message.success('上传成功')
    },
    handleSucces1 (res) {
      this.task.tipsIurl = res
      this.$message.success('上传成功')
    },
    handleSucces2 (res) {
      this.task.tipsVurl = res
      this.loading = false
      this.$message.success('上传成功')
    },
    handleSucces3 (res) {
      this.task.passImgUrl = res
      this.task.passMode = 3
      if (this.task.pass) {
        this.task.passMode = 1
      }
      this.$message.success('上传成功')
    },
    handleSucces4 (res) {
      this.task.passVideoUrl = res
      this.task.passMode = 4
      if (this.task.passImgUrl) {
        this.task.passMode = 3
      }
      if (this.task.pass) {
        this.task.passMode = 1
      }
      this.loading1 = false
      this.$message.success('上传成功')
    },
    passcli () {
      this.task.passMode = 1
    },
    async getinit (row, img = '', activity = null) {
      let datarow = {
        activityId: 0,
        adminId: 0,
        answer: '',
        answerMode: 0,
        bgiUrl: img,
        createBy: '',
        error: '',
        id: 0,
        modifyBy: '',
        pass: '',
        passMode: 0,
        position: '',
        remark: '',
        showTitle: 1,
        status: 0,
        taskName: '',
        tipsContent: '',
        tipsIurl: '',
        tipsVurl: '',
        passImgUrl: '',
        passVideoUrl: ''
      }
      this.mode = false,
      this.mode1 = false,
      this.mode2 = false,
      this.mode3 = false
      this.row = activity
      if (row.id) {
        const data = {
          entityId: row.id
        }
        const res = await getActivityTask(data)
        if (res.data) {
          datarow = res.data
        }
      }
      this.task = { ...datarow }
      if (this.task.showTitle == 1 || this.task.showTitle) {
        this.task.showTitle = true
      } else {
        this.task.showTitle = false
      }
      if (this.task.passVideoUrl) {
        this.task.passMode = 4
      }
      if (this.task.passImgUrl) {
        this.task.passMode = 3
      }
      if (this.task.pass) {
        this.task.passMode = 1
      }
      if (this.task.answerMode == 1) {
        this.pdclik(true)
      } else if (this.task.answerMode == 2) {
        this.pdclik1(true)
      } else if (this.task.answerMode == 3) {
        this.pdclik3(true)
      } else if (this.task.answerMode == 4) {
        this.pdclik2(true)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss'
</style>