<template>
  <div class="task">
    <div class="start_bottom">
      <div class="sb_left">
        <div class="sbl_top">
          <div>我的任务</div>
          <div @click="addlist"><img src="@/assets/add.png" alt="">新建任务</div>
        </div>
        <div class="sreach">
          <el-input placeholder="输入任务关键词" class="sreach_input" suffix-icon="el-icon-search" v-model="taskName" @change="serach"></el-input>
        </div>
        <div class="sbl_bottom">
          <div :class="type === index ? 'sbl_list active' : 'sbl_list'" v-for="(i, index) in list" :key="index" @click="active(index)">
            {{i.taskName}}
          </div>
        </div>
      </div>
      <div class="sb_right">
        <div class="sbr_left">
          <div class="sbrl_name">预览</div>
          <div class="sbrl_block">
            <img src="@/assets/sj_bj.png" alt="" class="sbrl_bj">
            <div class="simulation" style="z-index: 0;">
              <img :src="task?.bgiUrl" alt="" class="simulation_bj">
            </div>
            <div class="simulation">
              <div class="task_back">
                <img src="@/assets/back.png" alt="" class="back">
              </div>
              <div class="task_title" v-if="task?.showTitle">活动标题</div>
              <div class="task_team">
                <div class="team_top">
                  <img class="team_img" src="https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg" alt="">
                  <div class="task_teamname">队伍名称</div>
                </div>
                <div class="team_center">
                  <img src="@/assets/linel.png" alt="" class="linel">
                  <div class="team_center_name">共10人</div>
                  <img src="@/assets/line.png" alt="" class="linel">
                </div>
                <div class="team_bottom">
                  <img class="team_bottom_img" v-for="i in 10" :key="i" src="https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg" alt="">
                </div>
              </div>
              <div class="task_block">
                <div class="task_block_top">
                  {{task?.taskName}}
                </div>
                <div class="task_block_center">
                  <div class="ch_block" v-if="task?.tipsContent">
                    <img src="https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg" class="ch_left" />
                    <img src="@/assets/horn.png" class="horn" />
                    <div class="ch_bloc">
                      {{task?.tipsContent}}
                    </div>
                  </div>
                  <div class="ch_block" v-if="task?.tipsIurl">
                    <img src="https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg" class="ch_left" />
                    <img src="@/assets/horn.png" class="horn" />
                    <div class="ch_bloc">
                      <img :src="task?.tipsIurl" class="horn1" />
                    </div>
                  </div>
                  <div class="ch_block" v-if="task?.tipsVurl">
                    <img src="https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg" class="ch_left" />
                    <img src="@/assets/horn.png" class="horn" />
                    <div class="ch_bloc">
                      <video :src="task?.tipsVurl" class="horn3"></video>
                    </div>
                  </div>
                  <div class="ch_block" v-if="task?.pass">
                    <img src="https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg" class="ch_left" />
                    <img src="@/assets/horn.png" class="horn" />
                    <div class="ch_bloc">
                      {{task?.pass}}
                    </div>
                  </div>
                  <div class="ch_block" v-if="task?.passImgUrl">
                    <img src="https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg" class="ch_left" />
                    <img src="@/assets/horn.png" class="horn" />
                    <div class="ch_bloc">
                      <img :src="task?.passImgUrl" class="horn1" />
                    </div>
                  </div>
                  <div class="ch_block" v-if="task?.passVideoUrl">
                    <img src="https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg" class="ch_left" />
                    <img src="@/assets/horn.png" class="horn" />
                    <div class="ch_bloc">
                      <video :src="task?.passVideoUrl" class="horn3"></video>
                    </div>
                  </div>
                </div>
                <div class="task_block_bottom">
                  <div class="task_block_bottom_left">
                    {{mode ? '输入答案' : ''}}
                    {{mode2 ? '上传照片' : ''}}
                    {{mode3 ? '上传视频' : ''}}
                  </div>
                  <div class="task_block_bottom_right" v-if="mode">发送</div>
                  <img src="@/assets/update.png" v-if="mode2 || mode3" alt="" class="update">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="sbr_right">
          <div class="sbrr_top">
            <div class="sbrr_name">编辑</div>
            <div class="sbrr_bc" v-if="!fabu" @click="save">保存</div>
            <div class="sbrr_fb" v-if="fabu && task?.status == 2" @click="release1">发布到任务库</div>
            <div class="sbrr_fb" v-if="fabu" @click="release" style="margin-left: 20px">{{ task?.id != 0 ? task?.status == 2 ? '编辑' : '保存' : '保存'}}</div>
          </div>
          <div class="sbrr_block">
            <div class="sbrr_item" style="margin: 38px auto 0;">
              <div class="sbrri_name">任务简介</div>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="介绍任务的亮点和使用场景"
                v-model="task.remark"
                class="sbrr_desc5">
              </el-input>
            </div>
            <div class="sbrr_item" style="margin: 16px auto 0;">
              <div class="sbrri_name">任务名称</div>
              <el-input v-model="task.taskName" class="sbrr_input" placeholder=""></el-input>
            </div>
            <div class="sbrr_item" style="margin: 16px auto 0;">
              <div class="sbrri_name">任务提示</div>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="task.tipsContent"
                class="sbrr_desc4">
              </el-input>
            </div>
            <div class="sbrr_item" style="margin: 9px auto 0;">
              <div style="opacity: 0;" class="sbrri_name"></div>
              <el-upload
                style="width: 71px"
                class="avatar-uploader"
                :action="api + '/api/io/upload/'"
                :show-file-list="false"
                :on-success="handleSucces1"
                :before-upload="beforeUpload"
                :headers="{'token': token}">
                <img v-if="task.tipsIurl" :src="task.tipsIurl" class="avatar" style="padding-top: -10px">
                <span v-else>
                  <img src="@/assets/add2.png" alt="">
                </span>
              </el-upload>
              <div class="sbrr_itps">添加图片<br />图片格式为JPG</div>
            </div>
            <div class="sbrr_item" style="margin: 13px auto 0;">
              <div style="opacity: 0;" class="sbrri_name"></div>
              <el-upload
                style="width: 71px"
                class="avatar-uploader"
                :action="api + '/api/io/upload/'"
                :show-file-list="false"
                :on-success="handleSucces2"
                :before-upload="beforeUpload"
                :headers="{'token': token}">
                <span v-if="task.tipsVurl">
                  上传<br />
                  成功
                </span>
                <span v-else>
                  <img src="@/assets/add2.png" alt="">
                </span>
              </el-upload>
              <div class="sbrr_itps">添加视频<br />视频格式为MP4，最大50M</div>
            </div>
            <div class="sbrr_item">
              <div class="sbrri_name">任务判定</div>
              <div class="sbrri_right">
                <el-checkbox-group v-model="mode" @change="pdclik">
                  <el-checkbox label="文本判定">文本判定</el-checkbox>
                </el-checkbox-group>
                <el-checkbox-group v-model="mode1" @change="pdclik1">
                  <el-checkbox label="二维码扫码">二维码扫码</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <div class="sbrr_item" style="margin: 8px auto 0;">
              <div class="sbrri_name" style="opacity: 0;"></div>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="task.answer"
                class="sbrr_desc4">
              </el-input>
            </div>
            <div class="sbrr_item" style="margin: 8px auto 0;">
              <div class="sbrri_name" style="opacity: 0;"></div>
              <div class="sbrri_right">
                <el-checkbox-group v-model="mode2" @change="pdclik2">
                  <el-checkbox label="照片审核">照片审核</el-checkbox>
                </el-checkbox-group>
                <el-checkbox-group v-model="mode3" @change="pdclik3">
                  <el-checkbox label="视频审核">视频审核</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
            <div class="sbrr_item" style="margin: 16px auto 0;">
              <div class="sbrri_name">通过反馈</div>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="task.pass"
                class="sbrr_desc4">
              </el-input>
            </div>
            <div class="sbrr_item" style="margin: 9px auto 0;">
              <div style="opacity: 0;" class="sbrri_name"></div>
              <el-upload
                style="width: 71px"
                class="avatar-uploader"
                :action="api + '/api/io/upload/'"
                :show-file-list="false"
                :on-success="handleSucces3"
                :before-upload="beforeUpload"
                :headers="{'token': token}">
                <img v-if="task.passImgUrl" :src="task.passImgUrl" class="avatar" style="padding-top: -10px">
                <span v-else>
                  <img src="@/assets/add2.png" alt="">
                </span>
              </el-upload>
              <div class="sbrr_itps">添加图片<br />图片格式为JPG</div>
            </div>
            <div class="sbrr_item" style="margin: 13px auto 0;">
              <div style="opacity: 0;" class="sbrri_name"></div>
              <el-upload
                style="width: 71px"
                class="avatar-uploader"
                :action="api + '/api/io/upload/'"
                :show-file-list="false"
                :on-success="handleSucces4"
                :before-upload="beforeUpload"
                :headers="{'token': token}">
                <span v-if="task.passVideoUrl">
                  上传<br />
                  成功
                </span>
                <span v-else>
                  <img src="@/assets/add2.png" alt="">
                </span>
              </el-upload>
              <div class="sbrr_itps">添加视频<br />视频格式为MP4，最大50M</div>
            </div>
            <div class="sbrr_item" style="margin: 16px auto 0;">
              <div class="sbrri_name">错误反馈</div>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="task.error"
                class="sbrr_desc4">
              </el-input>
            </div>
            <div class="sbrr_item" style="margin: 25px auto 0;">
              <div class="sbrri_name">任务位置<br />（可选）</div>
              <el-input
                type="textarea"
                :rows="2"
                placeholder="位置信息"
                v-model="task.position"
                class="sbrr_desc6">
              </el-input>
              <img src="@/assets/position.png" class="position">
            </div>
            <div class="sbrr_item" style="margin: 34px auto 0;">
              <div class="sbrri_name">任务封面</div>
              <img src="@/assets/add2.png" alt="">
              <div class="sbrr_itps">添加图片<br />图片格式为JPG</div>
            </div>
            <div class="sbrr_item" style="margin: 16px auto 0;" v-if="fabu">
              <div class="sbrri_name">标签</div>
              <el-input v-model="task.label" class="sbrr_input" placeholder="多个标签请用,分割"></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api, getTaskPageList, getActivityTask, addActivityTask, updateActivityTask, publishTask, getMyTask, addStoreTask, updateStoreTask } from '@/api/api'
import base64 from '@/utils/base64';
export default {
  name: 'TaskView',
  props: {
    tshow: {
      type: Boolean,
      default: false
    },
    fabu: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    }
  },
  data() {
    return {
      token: new Date().getTime()+';'+base64.accumulation(new Date().getTime()),
      value: '',
      checkedCities: [],
      textarea: '',
      type: 0,
      impot: false,
      impotdr: false,
      list: [],
      input: '',
      show: false,
      taskName: '',
      task: {
        activityId: 0,
        adminId: 0,
        answer: '',
        answerMode: 0,
        bgiUrl: '',
        createBy: '',
        error: '',
        id: 0,
        modifyBy: '',
        pass: '',
        passMode: 0,
        position: '',
        remark: '',
        showTitle: 0,
        status: 0,
        taskName: '',
        tipsContent: '',
        tipsIurl: '',
        tipsVurl: '',
        passImgUrl: '',
        passVideoUrl: ''
      },
      mode: false,
      mode1: false,
      mode2: false,
      mode3: false,
      api: api
    }
  },
  methods: {
    beforeUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        this.$message.error('上传大小不能超过 50MB!');
      }
      return isLt2M;
    },
    active (type) {
      if (this.type == type) {
        return false
      }
      this.type = type
      if (this.list[type].id) {
        this.getdateil()
      } else {
        this.mode = this.mode1 = this.mode2 = this.mode3 = false
        this.task = {
          activityId: 0,
          adminId: this.user.adminId,
          answer: '',
          answerMode: 0,
          bgiUrl: '',
          createBy: '',
          error: '',
          id: 0,
          modifyBy: '',
          pass: '',
          passMode: 0,
          position: '',
          remark: '',
          showTitle: 0,
          status: 0,
          taskName: '',
          tipsContent: '',
          tipsIurl: '',
          tipsVurl: '',
          passImgUrl: '',
          passVideoUrl: ''
        }
      }
    },
    async save () {
      this.task.modifyBy = this.task.createBy = this.user.adminName
      this.task.adminId = this.user.adminId
      if (this.list[this.type].id) {
        const res = await updateActivityTask(this.task)
        if (res.data) {
          this.$message.success('保存成功')
          this.getinit()
        }
      } else {
        const res = await addActivityTask(this.task)
        if (res.data) {
          this.$message.success('保存成功')
          this.getinit()
        }
      }
    },
    async release1 () {
      const data = {
        taskId: this.list[this.type].id,
        user: this.user.adminName
      }
      const res = await publishTask(data)
      if (res.data) {
        this.mode = this.mode1 = this.mode2 = this.mode3 = false
        this.$message.success('发布成功')
        this.getinit()
      }
    },
    async release () {
      if (this.task.status == 2) {
        const res = await updateStoreTask(this.task)
        if (res.data) {
          this.$message.success('编辑成功')
          this.getlist()
        }
      } else {
        const res = await addStoreTask(this.task)
        if (res.code == 0) {
          this.$message.success('新增成功')
          this.getlist()
        }
      }
    },
    importshow () {
      window.scroll(0, 0);
      this.impot = !this.impot
      if (this.impot) {
        document.querySelector('body').setAttribute('style', 'overflow: hidden;')
      } else {
        document.querySelector('body').setAttribute('style', 'overflow: auto;')
      }
    },
    getimp () {
      this.impotdr = true
    },
    qr () {
      this.impotdr = false
      this.importshow()
    },
    addlist () {
      const data = {
        activityId: 0,
        adminId: this.user.adminId,
        answer: '',
        answerMode: 0,
        bgiUrl: '',
        createBy: '',
        error: '',
        id: 0,
        modifyBy: '',
        pass: '',
        passMode: 0,
        position: '',
        remark: '',
        showTitle: 0,
        status: 0,
        taskName: '',
        tipsContent: '',
        tipsIurl: '',
        tipsVurl: '',
        passImgUrl: '',
        passVideoUrl: ''
      }
      if (this.fabu) {
        data.label = '' 
        data.coverUrl = ''
      }
      data.taskName = '新建任务' + (this.list.length+1)
      if (data.showTitle == 1 || data.showTitle) {
        data.showTitle = true
      } else {
        data.showTitle = false
      }
      this.task = data
      this.list = [data,...this.list]
      // this.list.push(data)
    },
    pdclik (e) {
      this.task.passMode = 0
      this.task.answerMode = 0
      this.mode = this.mode1 = this.mode2 = this.mode3 = false
      if (e) {
        this.mode = true
        this.task.answerMode = 1
        this.task.passMode = 1
      }
    },
    pdclik1 (e) {
      this.task.passMode = 0
      this.task.answerMode = 0
      this.mode = this.mode1 = this.mode2 = this.mode3 = false
      if (e) {
        this.mode1 = true
        this.task.passMode = 2
        this.task.answerMode = 2
      }
    },
    pdclik2 (e) {
      this.task.passMode = 0
      this.task.answerMode = 0
      this.mode = this.mode1 = this.mode2 = this.mode3 = false
      if (e) {
        this.mode2 = true
        this.task.passMode = 4
        this.task.answerMode = 4
      }
    },
    pdclik3 (e) {
      this.task.passMode = 0
      this.task.answerMode = 0
      this.mode = this.mode1 = this.mode2 = this.mode3 = false
      if (e) {
        this.mode3 = true
        this.task.passMode = 3
        this.task.answerMode = 3
      }
    },
    handleSucces (res) {
      this.task.bgiUrl = res
      this.$message.success('上传成功')
    },
    handleSucces1 (res) {
      this.task.tipsIurl = res
      this.$message.success('上传成功')
    },
    handleSucces2 (res) {
      this.task.tipsVurl = res
      this.$message.success('上传成功')
    },
    handleSucces3 (res) {
      this.task.passImgUrl = res
      this.task.passVideoUrl = ''
      this.task.pass = ''
      this.task.passMode = 3
      this.$message.success('上传成功')
    },
    handleSucces4 (res) {
      this.task.passVideoUrl = res
      this.task.pass = ''
      this.task.passImgUrl = ''
      this.task.passMode = 4
      this.$message.success('上传成功')
    },
    getinit (id) {
      this.task = {
        activityId: 0,
        adminId: this.user.adminId,
        answer: '',
        answerMode: 0,
        bgiUrl: '',
        createBy: '',
        error: '',
        id: 0,
        modifyBy: '',
        pass: '',
        passMode: 0,
        position: '',
        remark: '',
        showTitle: 0,
        status: 0,
        taskName: '',
        tipsContent: '',
        tipsIurl: '',
        tipsVurl: '',
        passImgUrl: '',
        passVideoUrl: ''
      }
      if (this.fabu) {
        this.task = { ...this.task, label: '', coverUrl:'' }
      }
      this.taskName = ''
      this.getlist(id)
    },
    async getlist (id) {
      if (this.fabu) {
        const data = {
          taskName: this.taskName,
          adminId: this.user.adminId
        }
        const res = await getMyTask(data)
        this.list = res.data
        this.type = 0
      } else {
        const data = {
          adminId: this.user.adminId,
          pageIndex: 1,
          pageSize: 200
        }
        const res = await getTaskPageList(data)
        this.list = res.data.list
        this.list.forEach((res, index) => {
          if (id && res.id == id) {
            this.type = index
          }
        })
        if (!id) {
          this.type = 0
        }
      }
      if (id || this.list.length > 0) {
        this.getdateil()
      }
    },
    serach () {
      this.task = {
        activityId: 0,
        adminId: this.user.adminId,
        answer: '',
        answerMode: 0,
        bgiUrl: '',
        createBy: '',
        error: '',
        id: 0,
        modifyBy: '',
        pass: '',
        passMode: 0,
        position: '',
        remark: '',
        showTitle: 0,
        status: 0,
        taskName: '',
        tipsContent: '',
        tipsIurl: '',
        tipsVurl: '',
        passImgUrl: '',
        passVideoUrl: ''
      }
      if (this.fabu) {
        this.task = { ...this.task, label: '', coverUrl:'' }
      }
      this.mode = false
      this.mode1 = false
      this.mode2 = false
      this.mode3 = false
      this.getlist()
    },
    async getdateil () {
      if (this.fabu) {
        this.task = this.list[this.type]
      } else {
        let id = this.list[this.type].id
        const data = {
          entityId: id
        }
        const res = await getActivityTask(data)
        if (res.data) {
          this.task = res.data
        }
      }
      if (this.task?.showTitle == 1 || this.task?.showTitle) {
        this.task.showTitle = true
      } else {
        this.task.showTitle = false
      }
      if (this.task?.pass) {
        this.task.passMode = 1
      }
      if (this.task?.passImgUrl) {
        this.task.passMode = 3
      }
      if (this.task?.passVideoUrl) {
        this.task.passMode = 4
      }
      if (this.task?.answerMode == 1) {
        this.pdclik(true)
      } else if (this.task?.answerMode == 2) {
        this.pdclik1(true)
      } else if (this.task?.answerMode == 3) {
        this.pdclik3(true)
      } else if (this.task?.answerMode == 4) {
        this.pdclik2(true)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss'
</style>
