<template>
  <div class="sb_right">
    <div class="sbr_left">
      <div class="sbrl_name">预览</div>
      <div class="sbrl_block">
        <img src="@/assets/sj_bj.png" alt="" class="sbrl_bj" style="z-index: 10;">
        <div class="simulation" style="z-index: 0;">
          <img :src="mode.imgUrl" alt="" class="simulation_bj">
        </div>
        <div class="simulation">
          <div class="simulation_popup">
            <div class="simulation_popup_block">
              <div class="simulation_popup_block_title" style="white-space: pre-wrap;word-break: break-all;">{{mode.tips}}</div>
              <div class="simulation_popup_list">
                <div class="item_name">队伍名称</div>
                <div class="item_input">十字以内</div>
                <div class="item_name" style="margin-top: 32px">队伍头像</div>
                <div class="item_tx"></div>
              </div>
              <div class="simulation_popup_an">确认创建</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sbr_right">
      <div class="sbrr_name">编辑</div>
      <div class="sbrr_block">
        <div class="sbrr_title">
          【组队方式】<br />阐述组队的出发点以及说明
        </div>
        <div class="sbrr_titl" style="margin-top: 38px">组队文案</div>
        <el-input
          type="textarea"
          :rows="2"
          placeholder="请输入内容"
          v-model="mode.tips"
          class="sbrr_desc2">
        </el-input>
      </div>
    </div>
  </div>
</template>

<script>
import { getModeByActivity } from '@/api/api'
export default {
  name: 'StartupView',
  data() {
    return {
      mode: {
        activityId: 0,
        createBy: '',
        id: 0,
        imgUrl: '',
        modifyBy: '',
        remark: '',
        tips: ''
      }
    }
  },
  methods: {
    async getinit (row, img = '') {
      let datarow = {
        activityId: 0,
        createBy: '',
        id: 0,
        imgUrl: img,
        modifyBy: '',
        remark: '',
        tips: ''
      }
      if (row.id) {
        const data = {
          activityId: row.id
        }
        const res = await getModeByActivity(data)
        if (res.data) {
          datarow = res.data
        }
      }
      this.mode = { ...datarow }
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss'
</style>
  