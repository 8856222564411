import apiAxios from "@/api/apiAxios";
// const api = ''
const api = 'https://api.welvcheng.com'

export { api }

// 登录
export const loginIn = (data) =>
  apiAxios.post(api+'/api/admin/login', data)

// 注册
export const register = (data) =>
  apiAxios.post(api+'/api/admin/register', data)

// 获取验证码
export const getCode = (data) =>
  apiAxios.post(api+'/api/sms/getCode', data)

// 校验验证码
export const validCode = (data) =>
  apiAxios.post(api+'/api/sms/validCode', data)

// 修改密码
export const change = (data) =>
  apiAxios.post(api+'/api/admin/changePassword', data)

// 退出登录
export const logout = (data) =>
  apiAxios.post(api+'/api/admin/logout', data)

// 活动列表
export const getActivityPageList = (data) =>
  apiAxios.post(api+'/api/activity/getActivityPageList', data)

// 启动页新增修改
export const saveActivityLaunch = (data) =>
  apiAxios.post(api+'/api/activity/launch/saveActivityLaunch', data)

// 活动规则新增修改
export const saveActivityRule = (data) =>
  apiAxios.post(api+'/api/activity/rule/saveActivityRule', data)

// 组队方式新增修改
export const saveActivityMode = (data) =>
  apiAxios.post(api+'/api/activity/mode/saveActivityMode', data)

// 任务新增修改
export const saveActivityTask = (data) =>
  apiAxios.post(api+'/api/activity/task/saveActivityTask', data)

// 普通任务新增
export const addActivityTask = (data) =>
  apiAxios.post(api+'/api/activity/task/addActivityTask', data)

// 普通任务修改
export const updateActivityTask = (data) =>
  apiAxios.post(api+'/api/activity/task/updateActivityTask', data)

// 复制任务
export const copyTask = (data) =>
  apiAxios.post(api+'/api/activity/task/copyTask', data)

// 结果新增修改
export const saveActivityResult = (data) =>
  apiAxios.post(api+'/api/activity/result/saveActivityResult', data)

// 复制活动
export const copyActivity = (data) =>
  apiAxios.post(api+'/api/activity/copyActivity', data)

// 任务列表
export const getTaskPageList = (data) =>
  apiAxios.post(api+'/api/activity/task/getTaskPageList', data)

// 活动页新增修改
export const saveActivityWorkbench = (data) =>
  apiAxios.post(api+'/api/activity/workbench/saveActivityWorkbench', data)

// 删除任务
export const deleteActivityTask = (data) =>
  apiAxios.post(api+'/api/activity/task/deleteActivityTask', data)

// 获取启动页
export const getLaunchByActivity = (data) =>
  apiAxios.post(api+'/api/activity/launch/getLaunchByActivity', data)

// 获取规则
export const getActivityRuleByActivityId = (data) =>
  apiAxios.post(api+'/api/activity/rule/getActivityRuleByActivityId', data)

// 获取组队方式
export const getModeByActivity = (data) =>
  apiAxios.post(api+'/api/activity/mode/getModeByActivity', data)

// 获取结果页
export const getResultByActivity = (data) =>
  apiAxios.post(api+'/api/activity/result/getResultByActivity', data)

// 获取活动
export const getWorkbenchByActivity = (data) =>
  apiAxios.post(api+'/api/activity/workbench/getWorkbenchByActivity', data)

// 获取任务库
export const getTaskStore = (data) =>
  apiAxios.post(api+'/api/activity/task/getTaskStore', data)

// 获取任务
export const getActivityTask = (data) =>
  apiAxios.post(api+'/api/activity/task/getActivityTask', data)
  
// 获取任务
export const deleteActivity = (data) =>
  apiAxios.post(api+'/api/activity/deleteActivity', data)
  
// 获取活动
export const getActivity = (data) =>
  apiAxios.post(api+'/api/activity/getActivity', data)

//
export const importTasks = (data) =>
  apiAxios.post(api+'/api/activity/task/importTasks', data)

// 
export const getActivityTasks = (data) =>
  apiAxios.post(api+'/api/activity/task/getActivityTasks', data)

// 
export const getPageActivityBullets = (data) =>
  apiAxios.post(api+'/api/activity/bullet/getPageActivityBullets', data)

export const deleteActivityBullet = (data) =>
  apiAxios.post(api+'/api/activity/bullet/deleteActivityBullet', data)

export const publishTask = (data) =>
  apiAxios.post(api+'/api/activity/task/store/publishTask', data)

export const issueActivity = (data) =>
  apiAxios.post(api+'/api/activity/issueActivity', data)

export const getPageActivityMembers = (data) =>
  apiAxios.post(api+'/api/activity/team/member/getPageActivityMembers', data)

export const getPageActivityTeams = (data) =>
  apiAxios.post(api+'/api/activity/team/getPageActivityTeams', data)
  
export const getTemplates = (data) =>
  apiAxios.post(api+'/api/activity/template/getTemplates', data)

export const deleteActivityTeam = (data) =>
  apiAxios.post(api+'/api/activity/team/deleteActivityTeam', data)

export const getActivityStore = (data) =>
  apiAxios.post(api+'/api/activity/getActivityStore', data)

export const blackActivityMember = (data) =>
  apiAxios.post(api+'/api/activity/team/member/blackActivityMember', data)

export const deleteActivityMember = (data) =>
  apiAxios.post(api+'/api/activity/team/member/deleteActivityMember', data)
  
export const statisticsActivity = (data) =>
  apiAxios.post(api+'/api/app/statisticsActivity', data)

export const getTeamTasks = (data) =>
  apiAxios.post(api+'/api/activity/team/task/getTeamTasks', data)

export const getTeamTaskAuditAnswers = (data) =>
  apiAxios.post(api+'/api/activity/team/getTeamTaskAuditAnswers', data)

export const addStoreTask = (data) =>
  apiAxios.post(api+'/api/activity/task/store/addStoreTask', data)

export const getTaskStorestore = (data) =>
  apiAxios.post(api+'/api/activity/task/store/getTaskStore', data)
  
export const getMyTask = (data) =>
  apiAxios.post(api+'/api/activity/task/store/getMyTask', data)
  
export const updateStoreTask = (data) =>
  apiAxios.post(api+'/api/activity/task/store/updateStoreTask', data)
  
export const copyTask1 = (data) =>
  apiAxios.post(api+'/api/activity/task/store/copyTask', data)

export const getTaskAuditAnswers = (data) =>
  apiAxios.post(api+'/api/activity/team/getTaskAuditAnswers', data)

export const batchConfirmTaskResult = (data) =>
  apiAxios.post(api+'/api/activity/team/task/batchConfirmTaskResult', data)

export const batchPassBullet = (data) =>
  apiAxios.post(api+'/api/activity/bullet/batchPassBullet', data)

export const blackTeam = (data) =>
  apiAxios.post(api+'/api/activity/team/blackTeam', data)

export const getImportHistory = (data) =>
  apiAxios.post(api+'/api/activity/task/getImportHistory', data)

export const getPublishActivityPageList = (data) =>
  apiAxios.post(api+'/api/activity/getPublishActivityPageList', data)

export const addActivity = (data) =>
  apiAxios.post(api+'/api/activity/addActivity', data)

export const updateActivity = (data) =>
  apiAxios.post(api+'/api/activity/updateActivity', data)

export const getQRCode = (data) =>
  apiAxios.post(api+'/api/admin/getQRCode', data)
  
export const offlineActivity = (data) =>
  apiAxios.post(api+'/api/activity/offlineActivity', data) 

export const getRankingList = (data) =>
  apiAxios.post(api+'/api/activity/team/getRankingList', data) 

export const getPageActivityTeamMembers = (data) =>
  apiAxios.post(api+'/api/activity/team/member/getPageActivityTeamMembers', data) 

export const getPageRankingList = (data) =>
  apiAxios.post(api+'/api/activity/team/getPageRankingList', data) 

export const getActivityBullets = (data) =>
  apiAxios.post(api+'/api/activity/bullet/getActivityBullets', data) 
  
export const teamexport = (data) =>
  apiAxios.post(api+'/api/activity/team/export', data) 
  
export const setTeamTaskOrder = (data) =>
  apiAxios.post(api+'/api/activity/task/setTeamTaskOrder', data) 
  
export const down = (data) =>
  apiAxios.post(api+'/api/qrcode/down', data) 

export const getActivityTaskAnswers = (data) =>
  apiAxios.post(api+'/api/activity/task/answer/getActivityTaskAnswers', data) 
  
export const initActivityTaskAnswer = (data) =>
  apiAxios.post(api+'/api/activity/task/answer/initActivityTaskAnswer', data) 