import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: ''
  },
  getters: {
    user: state => state.user
  },
  mutations: {
    SETUSR: (state, user) => {
      state.user = user
    }
  },
  actions: {
    getInfo ({ commit }) {
      return new Promise((resolve) => {
        let user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : ''
        commit('SETUSR', user)
        resolve()
      })
    },
  },
  modules: {
  }
})
