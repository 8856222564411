<template>
  <div id="app">

    <router-view/>
  </div>
</template>
<script>
export default {
}
</script>

<style lang="scss">
#app {
  height: 100%;
}
* {
  margin: 0;
  padding: 0;
}
img {
  border: 0;
  vertical-align: middle
}
input {
  border: none;
}
input:focus{
  outline: none;
}
textarea {
  border: none;
}
textarea:focus {
  outline: none;
}
.el-picker-panel__footer .el-button--text.el-picker-panel__link-btn {
  display: none;
}
</style>
