<template>
  <div class="header">
    <div class="block">
      <div class="left">
        <img src="@/assets/logo.png" alt="logo" class="l_img">
        <div class="navigation">
          <div class="list" v-for="(i, index) in list" :key="index" @click="gotopage(i, index)">
            {{i.name}}
            <div v-if="xzindex == index" class="active"></div>
          </div>
        </div>
      </div>
      <div class="right" v-if="!user">
        <div class="login" @click="login('login')">登录</div>
        <div class="register" @click="login('register')">注册</div>
      </div>
      <el-dropdown class="right" v-if="user" @command="logout">
        <span class="right">
          <img :src="user.imgUrl" class="user_img" alt="">
          <div class="user_name">{{user.adminName}}</div>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <div class="eject_block" v-if="typestatus" @click="typestatus = null">
      <div class="eject_framee" @click.stop="typestatus = typestatus">
        <div class="login_block" v-show="typestatus === 'login'">
          <div class="login_title">
            登录
          </div>
          <div class="login_input">
            <img src="@/assets/user.png" alt="" class="login_img">
            <input type="text" v-model="loginfrom.mobile" class="login_inpu" placeholder="请输入手机号">
          </div>
          <div class="password_input">
            <img src="@/assets/lock.png" alt="" class="password_img">
            <input class="password_inpu" v-model="loginfrom.password" placeholder="请输入密码" type="password">
          </div>
          <div class="forget_block">
            <div class="forget_left">
              <el-checkbox-group v-model="checked" fill="#FF8A00">
                <el-checkbox label="记住我"></el-checkbox>
              </el-checkbox-group>
            </div>
            <div class="forget_right" @click="login('retrieve')">忘记密码</div>
          </div>
          <div class="login_button" @click="gotologin" v-prevent-re-click>登录</div>
          <div class="login_tips">还没有账号？<span class="gotoregister" @click="login('register')">去注册</span></div>
        </div>
        <div class="login_block" v-show="typestatus === 'register'">
          <div class="login_title">
            注册
          </div>
          <div class="register_user">
            <input v-model="registerfrom.mobile" type="text" class="register_input" placeholder="请输入手机号">
          </div>
          <div class="register_code">
            <div class="code_left">
              <input type="text" v-model="registerfrom.validCode" class="code_input" placeholder="请输入验证码">
            </div>
            <div class="code_right" @click="getcode" v-prevent-re-click>{{text}}</div>
          </div>
          <div class="register_user" style="margin-top: 0;">
            <input type="password" v-model="registerfrom.password" class="register_input" placeholder="密码（6-16位英文字母或数字，区分大小写）">
          </div>
          <div class="login_button" @click="registeruser" v-prevent-re-click>注册</div>
          <div class="login_tips">已有账号？<span class="gotoregister" @click="login('login')">去登录</span></div>
        </div>
        <div class="login_block" v-show="typestatus === 'retrieve'">
          <div class="login_title">
            找回密码
          </div>
          <div class="register_user">
            <input type="text" v-model="retrievefrom.mobile" class="register_input" placeholder="请输入手机号">
          </div>
          <div class="register_code">
            <div class="code_left">
              <input type="text" v-model="retrievefrom.validCode" class="code_input" placeholder="请输入验证码">
            </div>
            <div class="code_right" @click="getcode1" v-prevent-re-click>{{text}}</div>
          </div>
          <div class="login_button" @click="retrievelogin" v-prevent-re-click>确定</div>
          <div class="login_tips" @click="login('login')"><span class="gotoregister">我想起来了，去登录</span></div>
        </div>
        <div class="login_block" v-show="typestatus === 'resetcomplete1'">
          <div class="login_title">
            重置密码
          </div>
          <img src="@/assets/resetcomplete.png" alt="" class="res_img">
          <div class="res_cg">重置成功</div>
          <div class="res_login" @click="login('login')">确定</div>
        </div>
        <div class="login_block" v-show="typestatus === 'resetcomplete'">
          <div class="login_title">
            重置密码
          </div>
          <div class="register_user">
            <input type="password" v-model="retrievefrom.newPwd" class="register_input" placeholder="新密码（6-16位英文字母或数字，区分大小写）">
          </div>
          <div class="register_user">
            <input type="password" v-model="retrievefrom.newPwd1" class="register_input" placeholder="再次输入新密码">
          </div>
          <div class="login_button" @click="resetcompletelogin" v-prevent-re-click>确定</div>
          <div class="login_tips" @click="login('login')"><span class="gotoregister">我想起来了，去登录</span></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { loginIn, getCode, register, change, logout } from '@/api/api'
export default {
  name: 'HeaderView',
  computed: {
    userinfo () {
      return this.$store.getters.user
    }
  },
  props: ['xzindex'],
  data() {
    return {
      list: [
        {name: '首页', page: 'Index', path: '/', islogin: false},
        // {name: '任务库', page: 'task', path: '/task', islogin: true},
        {name: '活动库', page: 'activity', path: '/activity', islogin: true},
        {name: '策划中心', page: 'planningcenter', path: '/planningcenter', islogin: true},
        {name: '联系我们', page: 'contactus', path: '/contactus', islogin: false}
      ],
      typestatus: null,
      checked: false,
      loginfrom: {
        mobile: '',
        password: ''
      },
      registerfrom: {
        code: '',
        mobile: '',
        name: '',
        openId: '',
        password: '',
        validCode: '',
        checkCode: ''
      },
      retrievefrom: {
        mobile: '',
        newPwd: '',
        validCode: '',
        newPwd1: '',
        checkCode: ''
      },
      text: '获得验证码',
      codestatus: false,
      num: 60,
      user: '',
      id: 0,
      checkCode: ''
    }
  },
  methods: {
    beforeunloadFn () {
      localStorage.removeItem('type')
    },
    async logout () {
      const data = {
        token: this.user.token
      }
      const res = await logout(data)
      if (res.data.success) {
        this.$message.success('退出成功')
        localStorage.removeItem('user')
        localStorage.removeItem('type')
        this.user = res.data.data ? res.data.data : ''
        if (this.xzindex == 0) {
          return false
        }
        this.$router.push({
          name: 'Index'
        })
      }
    },
    async getcode () {
      if (this.codestatus) {
        return false
      }
      let that = this
      if (!that.registerfrom.mobile) {
        this.$message.error('请填写手机号码')
        return false
      }
      if (that.registerfrom.mobile.length !== 11) {
        this.$message.error('请填写正确的手机号码')
        return false
      }
      const data = {
        mobile: that.registerfrom.mobile
      }
      const res = await getCode(data)
      if (res.code == 0) {
        that.$message.success('发送成功')
        that.codestatus = true
        that.text = that.num + 'S后重新发送'
        that.checkCode = res.data.checkCode
        that.id = setInterval(() => {
          if (that.num <= 0) {
            that.text = '获得验证码'
            that.num = 60
            that.codestatus = false
            clearInterval(that.id)
          } else {
            that.num--
            that.text = that.num + 'S后重新发送'
          }
        }, 1000);
      }
    },
    async resetcompletelogin () {
      if (!this.retrievefrom.newPwd) {
        this.$message.error('请填写密码')
        return false
      }
      if (!this.retrievefrom.newPwd1) {
        this.$message.error('请填写密码')
        return false
      }
      if (this.retrievefrom.newPwd1 !== this.retrievefrom.newPwd) {
        this.$message.error('2次填写不一致')
        return false
      }
      this.retrievefrom.checkCode = this.checkCode
      const res = await change(this.retrievefrom)
      if (res.data.success) {
        this.login('resetcomplete1')
      } else {
        this.$message.error(res.data.message)
      }
    },
    retrievelogin () {
      if (!this.retrievefrom.mobile) {
        this.$message.error('请填写手机号码')
        return false
      }
      if (this.retrievefrom.mobile.length !== 11) {
        this.$message.error('请填写正确的手机号码')
        return false
      }
      if (!this.retrievefrom.validCode) {
        this.$message.error('请填写验证码')
        return false
      }
      this.login('resetcomplete')
    },
    async getcode1 () {
      if (this.codestatus) {
        return false
      }
      let that = this
      if (!that.retrievefrom.mobile) {
        this.$message.error('请填写手机号码')
        return false
      }
      if (that.retrievefrom.mobile.length !== 11) {
        this.$message.error('请填写正确的手机号码')
        return false
      }
      const data = {
        mobile: that.retrievefrom.mobile
      }
      const res = await getCode(data)
      if (res.code == 0) {
        this.$message.success('发送成功')
        that.codestatus = true
        that.text = that.num + 'S后重新发送'
        that.checkCode = res.data.checkCode
        that.id = setInterval(() => {
          if (that.num <= 0) {
            that.text = '获得验证码'
            that.num = 60
            that.codestatus = false
            clearInterval(that.id)
          } else {
            that.num--
            that.text = that.num + 'S后重新发送'
          }
        }, 1000);
      }
    },
    async registeruser () {
      if (!this.registerfrom.mobile) {
        this.$message.error('请填写手机号码')
        return false
      }
      if (this.registerfrom.mobile.length !== 11) {
        this.$message.error('请填写正确的手机号码')
        return false
      }
      if (!this.registerfrom.validCode) {
        this.$message.error('请填写验证码')
        return false
      }
      this.registerfrom.checkCode = this.checkCode
      const res = await register(this.registerfrom)
      if (res.code == 0 && res.data) {
        this.$message.success('注册成功')
        this.login('login')
      }
    },
    gotopage(item, index) {
      if (this.xzindex == index) {
        return false
      }
      if (item.islogin && !localStorage.getItem('user')) {
        this.$message.error('请登录')
        this.login('login')
        return false
      }
      if (localStorage.getItem('type')) {
        this.$confirm('为防信息丢失，离开页面前，请别忘记点击保存。是否确定离开?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then( () => {
          localStorage.removeItem('type')
          this.$router.push({
            name: item.page
          })
        }).catch(() => {
          return false
        });
        return false
      }
      this.$router.push({
        name: item.page
      })
    },
    login (type) {
      if (localStorage.getItem('loginfrom') && type == 'login') {
        this.loginfrom = JSON.parse(localStorage.getItem('loginfrom'))
        this.checked = true
      }
      if (!localStorage.getItem('loginfrom') && type == 'login') {
        this.loginfrom = {
          mobile: '',
          password: ''
        }
        this.checked = false
      }
      if (type == 'register') {
        this.registerfrom = {
          code: '',
          mobile: '',
          name: '',
          openId: '',
          password: '',
          validCode: '',
          checkCode: ''
        }
        this.checkCode = ''
        this.text = '获得验证码'
        this.num = 60
        this.codestatus = false
        clearInterval(this.id)
      }
      if (type == 'retrieve') {
        this.retrievefrom = {
          mobile: '',
          newPwd: '',
          validCode: '',
          newPwd1: '',
          checkCode: ''
        }
        this.checkCode = ''
        this.text = '获得验证码'
        this.num = 60
        this.codestatus = false
        clearInterval(this.id)
      }
      this.typestatus = type
    },
    async gotologin () {
      if (!this.loginfrom.mobile) {
        this.$message.error('请填写手机号')
        return false
      }
      if (this.loginfrom.mobile.length !== 11) {
        this.$message.error('请填写正确的手机号')
        return false
      }
      if (!this.loginfrom.password) {
        this.$message.error('请填写密码')
        return false
      }
      const res = await loginIn(this.loginfrom)
      if (res.data.code == 0) {
        if (this.checked) {
          localStorage.setItem('loginfrom', JSON.stringify(this.loginfrom))
        } else {
          localStorage.removeItem('loginfrom')
        }
        if (res.data.data) {
          localStorage.setItem('user', JSON.stringify(res.data.data))
          this.user = res.data.data ? res.data.data : ''
          if (this.user.isAdmin == 1) {
            this.list = [
              {name: '首页', page: 'Index', path: '/', islogin: false},
              // {name: '任务库', page: 'task', path: '/task', islogin: true},
              {name: '活动库', page: 'activity', path: '/activity', islogin: true},
              {name: '策划中心', page: 'planningcenter', path: '/planningcenter', islogin: true},
              {name: '联系我们', page: 'contactus', path: '/contactus', islogin: false},
              {name: '后台', page: 'backstage', path: '/backstage', islogin: true}
            ]
          }
        }
        this.typestatus = false
        this.$message.success('登录成功')
      } else {
        this.$message.error(res.data.msg)
      }
    }
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
    this.user = this.userinfo
    if (this.user.isAdmin == 1) {
      this.list = [
        {name: '首页', page: 'Index', path: '/', islogin: false},
        // {name: '任务库', page: 'task', path: '/task', islogin: true},
        {name: '活动库', page: 'activity', path: '/activity', islogin: true},
        {name: '策划中心', page: 'planningcenter', path: '/planningcenter', islogin: true},
        {name: '联系我们', page: 'contactus', path: '/contactus', islogin: false},
        {name: '后台', page: 'backstage', path: '/backstage', islogin: true}
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
@import './header.scss'
</style>
