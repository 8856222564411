<template>
  <div class="upload-container">
      <div class="upload-content">
        <div class="img-box" v-loading="loading" element-loading-text="图片上传中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
          <el-upload
            style="width: 71px"
            class="avatar-uploader"
            :action="api + '/api/io/upload/'"
            :show-file-list="false"
            :on-remove="handleRemove"
            :before-upload="beforeUpload"
            :on-success="handleSuccess"
            :headers="{'token': token}">
            <img v-if="imageUrl" :src="imageUrl" class="avatar" style="padding-top: -10px">
            <span v-else>
              上传png,jpg
            </span>
          </el-upload>
        </div>
      </div>
  </div>
</template>

<script>
import { api } from '@/api/api'
import base64 from '@/utils/base64';
export default {
  name: 'UpImgNew',
  props: {
    keyname:{
      type: String
    }
  },
  data () {
    return {
      listObj: {},
      imageUrl:'',
      loading: false,
      api: api,
      token: new Date().getTime()+';'+base64.accumulation(new Date().getTime())
    }
  },
  methods: {
    checkAllSuccess () {
      return Object.keys(this.listObj).every(item => this.listObj[item].hasSuccess)
    },
    handleSubmit () {
      const arr = Object.keys(this.listObj).map(v => this.listObj[v])
      if (!this.checkAllSuccess()) {
        this.$message('请等待所有图片上传完毕. 如遇到网络问题请刷新页面!')
        return
      }
      this.$emit('uploadCallback', arr)
      this.listObj = {}
    },
    handleSuccess (res) {
      this.imageUrl = res
      this.loading = false
      this.$emit('sendUrl',this.imageUrl,this.keyname)
    },
    handleRemove (file) {
      const uid = file.uid
      const objKeyArr = Object.keys(this.listObj)
      for (let i = 0, len = objKeyArr.length; i < len; i++) {
        if (this.listObj[objKeyArr[i]].uid === uid) {
          delete this.listObj[objKeyArr[i]]
          return
        }
      }
    },
    beforeUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        this.$message.error('上传大小不能超过 50MB!');
      }
      return isLt2M;
    }
  },
  mounted () {

  },
}
</script>

<style  scoped>
.editor-slide-upload {
  margin-bottom: 20px;

}
::v-deep  .editor-slide-upload .el-upload--picture-card{
  width:100%;
}
</style>
<style scoped>
.form{
  margin-bottom:20px;
}
.upload-container{
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9;
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #C6C6C6;
}
::v-deep .avatar-uploader .el-upload {
  cursor: pointer;
  position: relative; 
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  padding-top: 10px;
  line-height: 17px;
}
::v-deep  .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 60px;
  height: 60px;
  line-height: 60px;
  text-align: center;
}
.avatar {
  width: 60px;
  height: 60px;
  display: block;
}
.img-box{
  display: flex;
  justify-content: center;
  align-items: center;
  color: #858585;
}
</style>
