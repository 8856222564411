<template>
  <div class="index">
    <HeaderView :xzindex="3"></HeaderView>
    <div class="title">我们致力于提供一个线上活动平台，在这个平台上：</div>
    <div class="desc">组织者可以自己轻松地设计策划团队活动，<br />活动发布后，参与者可以便捷地通过小程序参与活动并获得良好的体验。</div>
    <div class="tips">如果您有任何建议或者疑惑，请与我们联系</div>
    <div class="block">
      <div class="top">
        <div :class="xzindex == 0 ? 'active' : ''" @click="tabshow(0)">微信</div>
        <div :class="xzindex == 1 ? 'active' : ''" @click="tabshow(1)">联系</div>
      </div>
      <div class="ushow" v-show="xzindex == 1">
        <div class="ustitle">欢迎您拨打We旅程服务热线</div>
        <div class="usdesc">服务热线：17891952232</div>
      </div>
      <div class="ushow" v-show="xzindex == 0">
        <div class="ustitle">欢迎您通过微信和我们联系</div>
        <div class="ercode">
          <div class="er_left">
            <div class="er_top">您可以搜索微信号或扫描右边二维码添加We旅程客服</div>
            <div class="er_bottom">微信号：welvcheng</div>
          </div>
          <img src="@/assets/ercode.jpg" alt="" class="ercode_right">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderView from '@/components/header/HeaderView.vue'
export default {
  name: 'ContactusView',
  components: {
    HeaderView
  },
  data() {
    return {
      xzindex: 0
    }
  },
  methods: {
    tabshow(index) {
      if(index == this.xzindex) {
        return false
      }
      this.xzindex = index
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss'
</style>
