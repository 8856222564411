<template>
  <div class="index">
    <HeaderView :xzindex="0"></HeaderView>
    <div class="title">
      <img src="@/assets/logo1.png" alt="">
    </div>
    <div class="desc">轻松创意你的团队活动</div>
    <div class="describe">用创意，为你的团队打造一段值得铭记的体验旅程</div>
    <div class="operation">
      <div @click="gotopage('planningcenter')">开始策划</div>
      <div @click="gotopage('contactus')">联系我们</div>
    </div>
    <div class="bottom">
      <a href="https://beian.miit.gov.cn" target="_blank">沪ICP备2022032614号-1</a>
    </div>
  </div>
</template>

<script>
import HeaderView from '@/components/header/HeaderView.vue'
export default {
  name: 'IndexView',
  components: {
    HeaderView
  },
  mounted() {
  },
  methods: {
    gotopage (item) {
      this.$router.push({
        name: item
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss'
</style>
  