<template>
  <div class="startup" ref="scrollDiv">
    <div class="start_top" v-if="!show">
      <el-form :model="activity" class="demo-form-inline" label-width="100px">
        <el-row :gutter="10" type="flex">
          <el-col :span="12">
            <el-form-item label="活动标题">
              <el-input v-model="activity.name" placeholder="请输入活动标题,完整显示建议在9个字及以内"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="活动简介">
              <el-input v-model="activity.introduction" class="trb_input" placeholder="简介（20字以上，简要说明目的、对象、思路等）"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-collapse-transition>
          <div v-show="advanced">
            <el-row :gutter="10" type="flex">
              <el-col :span="12">
                <el-form-item label="头像和昵称">
                  <div class="trt_left">
                    <div style="width: 60px;height: 60px;flex: 120px;position: relative;">
                      <img style="width: 60px;height: 60px" :src="activity.imageUrl" v-if="activity.imageUrl">
                      <imgUpload style="width: 60px;height: 60px;" keyname="activity" @sendUrl="sendUrl" :style="!activity.imageUrl ? 'opacity: 1' : 'opacity: 0'"></imgUpload>
                    </div>
                    <el-input v-model="activity.nickName" placeholder="昵称"></el-input>
                    <el-tooltip class="item" effect="dark" content="你可以理解活动的官方人物，任务将以TA的口吻发出，通常是吉祥物、老板等" placement="top-start">
                      <img src="@/assets/doubt.png" alt="" class="trt_img">
                    </el-tooltip>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="开始时间">
                  <el-date-picker
                    v-model="activity.beginTime"
                    type="datetime"
                    placeholder="选择日期时间"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    class="date_picker">
                  </el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10" type="flex">
              <el-col :span="12">
                <el-form-item label="活动城市">
                  <el-input v-model="activity.cityCode" placeholder="城市（选填）"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="结束时间">
                  <el-date-picker
                    v-model="activity.endTime"
                    type="datetime"
                    placeholder="选择日期时间"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    class="date_picker">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="" label-width="20px">
                  <el-checkbox-group v-model="timestype" style="margin-left: 10px" @change="activity.endTime = ''">
                    <el-checkbox label="1">不限时间</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10" type="flex">
              <el-col :span="12">
                <el-form-item label="主办公司">
                  <el-input v-model="activity.companyName" placeholder="公司（选填）"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="任务玩法">
                  <el-select v-model="activity.taskMode" placeholder="请选择">
                    <el-option
                      label="按顺序完成任务"
                      :value="1">
                    </el-option>
                    <el-option
                      label="并行完成任务"
                      :value="2">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10" type="flex">
              <el-col :span="12">
                <el-form-item label="" label-width="0">
                  <el-checkbox-group v-model="activity.openBullet" style="margin-left: 16px;">
                    <el-checkbox label="开通弹幕">开通弹幕</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
              </el-col>
              <el-col :span="4">
                <el-form-item label="参与方式">
                  <el-select v-model="activity.playMode" placeholder="请选择">
                    <el-option
                      label="个人玩"
                      :value="1">
                    </el-option>
                    <el-option
                      label="组队玩"
                      :value="2">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="activity.playMode == 2">
                <el-form-item label="">
                  <div style="display: flex;">
                    <el-checkbox-group v-model="checkedCities" @change="checkedCiti" v-if="activity.playMode == 2">
                      <el-checkbox label="固定人数">固定人数</el-checkbox>
                    </el-checkbox-group>
                    <div v-if="activity.playMode == 2" style="margin-left: 13px">
                      <el-input-number v-model="activity.limitedNum" :disabled="!checkedCities" size="mini" controls-position="right" :max="10"></el-input-number>
                    </div>
                  </div>
                  <div style="display: flex;">
                    <el-checkbox-group v-model="checkedCities1" @change="checkedCiti1" v-if="activity.playMode == 2">
                      <el-checkbox label="固定人数">每组最大人数</el-checkbox>
                    </el-checkbox-group>
                    <div v-if="activity.playMode == 2" style="margin-left: 13px">
                      <el-input-number v-model="activity.teamMaxNum" :disabled="!checkedCities1" size="mini" controls-position="right" :max="10"></el-input-number>
                    </div>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="8" v-if="activity.playMode == 1">
                <el-form-item label="参与人数">
                  <div v-if="activity.playMode == 1">
                    <el-input-number v-model="activity.playerNum" placeholder="参与人数" size="mini" controls-position="right"></el-input-number>
                  </div>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="10" type="flex" v-if="activity.isStore == 1">
              <el-col :span="12">
                <el-form-item label="标签">
                  <el-input v-model="activity.label" placeholder="多个标签请用,分割"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="editactivity" @click="editactivity">保存</div>
          </div>
        </el-collapse-transition>
        <div class="reg-btn">
          <div class="right-handle-group" @click="toggleAdvanced">
            <i :class="advanced ? 'el-icon-arrow-up' : 'el-icon-arrow-down'" style="cursor: pointer;" />
          </div>
        </div>
      </el-form>
    </div>
    <div class="start_bottom" v-if="!show">
      <div class="sb_left">
        <div class="sbl_top">
          <div @click="save" :class="activity.id ? '' : 'active'">保存</div>
          <div @click="release" :class="activity.id ? '' : 'active'">发布</div>
        </div>
        <div class="sbl_bottom">
          <div :class="type === 0 ? 'sbl_item active' : 'sbl_item'" @click="active(0)">
            <img src="@/assets/home.png" alt="">
            启动页
          </div>
          <div :class="type === 1 ? 'sbl_item active' : 'sbl_item'" @click="active(1)">
            <img src="@/assets/setup.png" alt="">
            活动规则
          </div>
          <div :class="type === 2 ? 'sbl_item active' : 'sbl_item'" @click="active(2)">
            <img src="@/assets/user1.png" alt="">
            组队方式
          </div>
          <div :class="type === 3 || type === 5 ? 'sbl_item active' : 'sbl_item'" @click="active(3)">
            <img src="@/assets/user1.png" alt="">
            活动页
            <img src="@/assets/bottom.png" alt="">
          </div>
          <div class="sbl_but">
            <img src="@/assets/add1.png" alt="">
            <div style="margin-right: 15px;" @click="importshow()">导入任务</div>
            <div @click="addlist">新建任务</div>
          </div>
          <div :class="typeindex === index ? 'sbl_list active' : 'sbl_list'" @click="clitypetypeindex(i, index) " v-for="(i, index) in list" :key="index">
            <div class="dian"></div>
            <div class="taskName">
              {{i.taskName}}
            </div>
            <div class="sct el-icon-error" @click.stop="sctdelete(i, index)"></div>
          </div>
          <div class="sbl_but">
            <div @click="sortask()">任务排序</div>
          </div>
          <div :class="type === 4 ? 'sbl_item active' : 'sbl_item'" style="margin-top: 54px" @click="active(4)">
            <img src="@/assets/collection.png" alt="">
            完成页
          </div>
        </div>
      </div>
      <startup v-show="type === 0" ref="startup"></startup>
      <rule v-show="type === 1" ref="rule"></rule>
      <mode v-show="type === 2" ref="mode"></mode>
      <activity v-show="type === 3" ref="activity"></activity>
      <result v-show="type === 4" ref="result"></result>
      <taska v-show="type === 5" ref="taska"></taska>
    </div>
    <div class="import" v-if="impot && !show" @click="importshow">
      <div class="im_block" @click.stop="impot = true" v-if="!impotdr">
        <div class="icon el-icon-close" @click="importshow"></div>
        <div class="search">
          <input type="text" v-model="taskName">
          <img src="@/assets/search.png" alt="" @click="getlist">
        </div>
        <div class="im_list">
          <div class="im_item" v-for="i in Historylist" :key="i.id" @click="getimp(i)">
            {{i.name}}
          </div>
        </div>
        <div class="imp_block">
          <div class="imp_list">
            <span>我的任务</span>
            <div class="list_block">
              <div class="list_item" v-for="(i,index) in tasklist" :key="index" @click="getimp(i)">{{i.taskName}}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="imb_block" v-if="impotdr" @click.stop="impot = true">
        <div class="imb_ti">确定导入任务？</div>
        <div class="img_qr">
          <div @click="qr(1)">取消</div>
          <div @click="qr(2)" style="margin-left: 26px">确定</div>
        </div>
      </div>
    </div>
    <task v-if="show"></task>
    <el-dialog
      title="任务排序"
      :visible.sync="sortshow"
      width="60%">
      <div style="max-height: 500px;overflow-y: auto;">
        <div style="width: 100%;height: 40px;font-size: 20px;" v-for="(i, index) in sortlist" :key="index" @dragstart="dragstart(index)" @dragenter="dragenter($event, index)"
        @dragover="dragover($event, index)" draggable= "true">{{ i.taskName }}</div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="sortshow = false">取 消</el-button>
        <el-button type="primary" @click="sortclick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { setTeamTaskOrder, getActivityTasks, initActivityTaskAnswer, importTasks, getImportHistory, deleteActivityTask, addActivity, updateActivity, getActivity, issueActivity, getTaskPageList, saveActivityLaunch, saveActivityRule, saveActivityMode, saveActivityResult, saveActivityTask, saveActivityWorkbench } from '@/api/api'
import imgUpload from './UpImgNew.vue'
import task from '@/components/task/index.vue'
import startup from './startup.vue'
import rule from './rule.vue'
import mode from './mode.vue'
import activity from './activity.vue'
import result from './result.vue'
import taska from './taska.vue'
export default {
  name: 'StartupView',
  components: {
    imgUpload,
    task,
    startup,
    rule,
    mode,
    activity,
    result,
    taska
  },
  computed: {
    user () {
      return this.$store.getters.user
    }
  },
  data() {
    return {
      taskName: '',
      checkedCities: false,
      checkedCities1: false,
      textarea: '',
      type: 0,
      impot: false,
      impotdr: false,
      list: [],
      input: '',
      show: false,
      activity: {
        adminId: 0,
        beginTime: '',
        bgmUrl: '',
        bgvUrl: '',
        cityCode: '',
        companyName: '',
        createBy: '',
        createOn: '',
        endTime: '',
        id: 0,
        imageUrl: '',
        introduction: '',
        isDeleted: 0,
        limitedNum: 0,
        modifyBy: '',
        modifyOn: '',
        name: '',
        nickName: '',
        openBullet: 0,
        playMode: 1,
        playerNum: 0,
        remark: '',
        status: 0,
        taskMode: 1,
        teamMaxNum: 0,
        isStore: 2,
        label: ''
      },
      launch: {
        activityId: 0,
        bgiUrl: '',
        bgmName: '',
        bgmUrl: '',
        bgvUrl: '',
        createBy: '',
        createOn: '',
        id: 0,
        isDeleted: 0,
        modifyBy: '',
        modifyOn: '',
        remark: '',
        showTitle: 0,
        templateId: 0,
        tips: ''
      },
      rule: {
        activityId: 0,
        createBy: '',
        id: 0,
        imgUrl: '',
        modifyBy: '',
        remark: '',
        tips: ''
      },
      mode: {
        activityId: 0,
        createBy: '',
        id: 0,
        imgUrl: '',
        modifyBy: '',
        remark: '',
        tips: ''
      },
      task: {
        activityId: 0,
        adminId: 0,
        answer: '',
        answerMode: 0,
        bgiUrl: '',
        createBy: '',
        error: '',
        id: 0,
        modifyBy: '',
        pass: '',
        passMode: 0,
        position: '',
        remark: '',
        showTitle: 0,
        status: 0,
        taskName: '',
        tipsContent: '',
        tipsIurl: '',
        tipsVurl: '',
        passImgUrl: '',
        passVideoUrl: ''
      },
      result: {
        activityId: 0,
        bgiTips: '',
        bgiUrl: '',
        createBy: '',
        id: 0,
        modifyBy: '',
        remark: '',
        activityName: ''
      },
      workbench: {
        activityId: 0,
        beginIcon: '',
        bgiUrl: '',
        bgmUrl: '',
        bgvUrl: '',
        bulletNum: 0,
        createBy: '',
        endIcon: '',
        id: 0,
        isDeleted: 0,
        isSame: 0,
        likeNum: 0,
        modifyBy: '',
        remark: '',
        showTitle: 0,
        sortType: 0,
        templateId: 0
      },
      typeindex: null,
      timestype: '',
      tasklist: [],
      datajs: null,
      Historylist: [],
      advanced: false,
      sortshow: false,
      sortlist: [],
      dragIndex: '',
      enterIndex: '',
      timeout: null,
    }
  },
  destroyed() {
   // 每次离开当前界面时，清除定时器
    clearInterval(this.timeout)
    this.timeout = null
  },
  methods: {
    async sortask () {
      const data = {
        activityId: this.activity.id
      }
      const res = await getActivityTasks(data)
      this.sortlist = res.data
      this.sortshow = true
    },
    dragstart(index) {
      this.dragIndex = index
    },
    dragenter(e,index) {  
      e.preventDefault();
      this.enterIndex = index
      if( this.timeout !== null) {
        clearTimeout(this.timeout)
      }
      // 拖拽事件的防抖
      this.timeout = setTimeout(() => {
         if( this.dragIndex !== index){
            const source = this.sortlist[this.dragIndex]
            this.sortlist.splice(this.dragIndex,1)
            this.sortlist.splice(index, 0 , source )
            // 排序变化后目标对象的索引变成源对象的索引
            this.dragIndex = index;
          }
      }, 100);
    },
    dragover(e, index) {
      console.log(index)
      e.preventDefault();
    },
    async sortclick () {
      let list = []
      this.sortlist.forEach((item, index) => {
        list.push({order:index, taskId: item.id})
      })
      const data = {
        activityId: this.activity.id,
        orderList: list,
        user: this.user.adminName
      }
      const res = await setTeamTaskOrder(data)
      this.$message.success(res.message)
      this.sortshow = false
      this.getActivityTasks()
    },
    sctdelete (item, index) {
      this.$confirm('此操作将永久删除该任务, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        if (item.id) {
          const data = {
            entityId: item.id,
            operator: this.user.adminName
          }
          const res = await deleteActivityTask(data)
          if (res.data) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.list.splice(index, 1)
            if (this.list.length > 0) {
              this.typeindex = 0
              this.type = 5
              this.$nextTick(() => {
                this.$refs.taska.getinit(this.list[0], this.$refs.startup.launch.bgiUrl || this.$refs.startup.showimg, this.activity)
              })
            }
          }
        } else {
          this.list.splice(index, 1)
        }
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });          
      });
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    async editactivity () {
      if (this.activity.id) {
        const data = {
          activity: { ...this.activity}
        }
        if (!data.activity.beginTime) {
          this.$message.error('请选择开始时间')
          return false
        }
        if (this.timestype) {
          data.activity.endTime = '3022-11-08 00:00:00'
        }
        if (!data.activity.endTime) {
          this.$message.error('请选择结束时间')
          return false
        }
        data.activity.modifyBy = data.activity.createBy = this.user.adminName
        data.activity.openBullet = data.activity.openBullet || data.activity.openBullet == 1 ? 1 : 2
        const res = await updateActivity(data.activity)
        if (res.data) {
          this.$message.success('保存成功')
        }
      } else {
        const data = {
          activity: { ...this.activity}
        }
        if (!data.activity.beginTime) {
          this.$message.error('请选择开始时间')
          return false
        }
        if (this.timestype) {
          data.activity.endTime = '3022-11-08 00:00:00'
        }
        if (!data.activity.endTime) {
          this.$message.error('请选择结束时间')
          return false
        }
        data.activity.modifyBy = data.activity.createBy = this.user.adminName
        data.activity.openBullet = data.activity.openBullet || data.activity.openBullet == 1 ? 1 : 2
        const res = await addActivity(data.activity)
        if (res.code == 0) {
          this.activity.id = res.data
          this.$message.success('保存成功')
        }
      }
      
    },
    async getinit (id) {
      localStorage.setItem('type', true)
      if (!id) {
        this.advanced = true
      }
      const data = {
        entityId: id
      }
      const res = await getActivity(data)
      if (res.data) {
        this.activity = res.data
        this.activity.openBullet = this.activity.openBullet == 1 ? true : false
        this.activity.beginTime = this.activity.beginTimeStr
        this.activity.endTime = this.activity.endTimeStr
        if (this.activity.limitedNum) {
          this.checkedCities = true
        }
        if (this.activity.teamMaxNum) {
          this.checkedCities1 = true
        }
        if (this.activity.endTime == '3022-11-08 00:00:00') {
          this.timestype = true
          this.activity.endTime = ''
        }
      }
      this.$nextTick(() => {
          this.$refs.startup.getinit(this.activity)
      })
      if (this.activity.id > 0) {
        this.getActivityTasks()
      }
    },
    async getActivityTasks () {
      const data = {
        activityId: this.activity.id
      }
      const res = await getActivityTasks(data)
      this.list = res.data
    },
    checkedCiti (e) {
      this.checkedCities = e
      this.checkedCities1 = !e
      this.activity.teamMaxNum = 0
      this.activity.limitedNum = 0
    },
    checkedCiti1 (e) {
      this.checkedCities1 = e
      this.checkedCities = !e
      this.activity.teamMaxNum = 0
      this.activity.limitedNum = 0
    },
    active (type) {
      let that = this
      if (type == 2 && this.activity.playMode != 2) {
        return false
      }
      that.$confirm('为防信息丢失，离开页面前，请别忘记点击保存。是否确定离开?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then( () => {
        that.typeindex = null
        if (type == 0) {
          that.$nextTick(() => {
            that.$refs.startup.getinit(that.activity)
          })
        } else if (type == 1) {
          that.$nextTick(() => {
            that.$refs.rule.getinit(that.activity, this.$refs.startup.launch.bgiUrl || this.$refs.startup.showimg)
          })
        } else if (type == 2 && this.activity.playMode == 2) {
          that.$nextTick(() => {
            that.$refs.mode.getinit(that.activity, this.$refs.startup.launch.bgiUrl || this.$refs.startup.showimg)
          })
        } else if (type == 3) {
          that.$nextTick(() => {
            that.$refs.activity.getinit(that.activity, this.list)
          })
        } else if (type == 4) {
          that.$nextTick(() => {
            that.$refs.result.getinit(that.activity, this.$refs.startup.launch.bgiUrl || this.$refs.startup.showimg)
          })
        }
        this.type = type
      }).catch(() => {
        return false
      });
    },
    timeget (e) {
      if (e && e.length > 0) {
        this.activity.beginTime = e[0]
        this.activity.endTime = e[1]
      } else {
        this.activity.beginTime = ''
        this.activity.endTime = ''
      }
    },
    async save () {
      if (!this.activity.id) {
        this.$message.error('请先创建活动')
        return false
      }
      if (this.type == 0) {
        if (!this.activity.beginTime) {
          this.$message.error('请选择开始时间')
          return false
        }
        const data = {
          launch: { ...this.$refs.startup.launch},
          activity: { ...this.activity}
        }
        if (!data.launch.bgiUrl && !data.launch.templateId) {
          this.$message.error('请上传背景图')
          return false
        }
        if (this.activity.id) {
          data.launch.activityId = this.activity.id
        }
        if (this.timestype) {
          data.activity.endTime = '3022-11-08 00:00:00'
        }
        if (!data.activity.endTime) {
          this.$message.error('请选择结束时间')
          return false
        }
        if (!data.launch.templateId) {
          data.launch.templateId = 0
        }
        data.launch.modifyBy = data.launch.createBy = data.activity.modifyBy = data.activity.createBy = this.user.adminName
        data.launch.showTitle = data.launch.showTitle ? 1 : 0
        data.activity.openBullet = data.activity.openBullet || data.activity.openBullet == 1 ? 1 : 2
        const res = await saveActivityLaunch(data)
        if (res.data.success) {
          this.activity.id = res.data.activityId
          this.launch = this.$refs.startup.launch
          this.launch.id = res.data.launchId
          this.launch.activityId = res.data.activityId
          this.$message.success('保存成功')
        }
      } else if (this.type == 1) {
        const data = {
          rule: { ...this.$refs.rule.rule},
          activity: { ...this.activity}
        }
        if (!this.activity.beginTime) {
          this.$message.error('请选择开始时间')
          return false
        }
        if (!data.rule.imgUrl) {
          this.$message.error('请上传规则说明图')
          return false
        }
        if (this.activity.id) {
          data.rule.activityId = this.activity.id
        }
        if (this.timestype) {
          data.activity.endTime = '3022-11-08 00:00:00'
        }
        if (!data.activity.endTime) {
          this.$message.error('请选择结束时间')
          return false
        }
        data.rule.modifyBy = data.rule.createBy = data.activity.modifyBy = data.activity.createBy = this.user.adminName
        data.activity.openBullet = data.activity.openBullet || data.activity.openBullet == 1 ? 1 : 2
        const res = await saveActivityRule(data)
        if (res.data.success) {
          this.activity.id = res.data.activityId
          this.rule = this.$refs.startup.launch
          this.rule.id = res.data.ruleId
          this.rule.activityId = res.data.activityId
          this.$message.success('保存成功')
        }
      } else if (this.type == 2) {
        const data = {
          mode: { ...this.$refs.mode.mode},
          activity: { ...this.activity}
        }
        if (!this.activity.beginTime) {
          this.$message.error('请选择开始时间')
          return false
        }
        if (this.activity.id) {
          data.mode.activityId = this.activity.id
        }
        if (this.timestype) {
          data.activity.endTime = '3022-11-08 00:00:00'
        }
        if (!data.activity.endTime) {
          this.$message.error('请选择结束时间')
          return false
        }
        data.mode.modifyBy = data.mode.createBy = data.activity.modifyBy = data.activity.createBy = this.user.adminName
        data.activity.openBullet = data.activity.openBullet || data.activity.openBullet == 1 ? 1 : 2
        const res = await saveActivityMode(data)
        if (res.data.success) {
          this.activity.id = res.data.activityId
          this.mode = this.$refs.mode.mode
          this.mode.id = res.data.modeId
          this.mode.activityId = res.data.activityId
          this.$message.success('保存成功')
        }
      } else if (this.type == 3) {
        const data = {
          workbench: { ...this.$refs.activity.workbench},
          activity: { ...this.activity}
        }
        if (!this.activity.beginTime) {
          this.$message.error('请选择开始时间')
          return false
        }
        if (!data.workbench.bgiUrl && !data.workbench.templateId) {
          this.$message.error('请上传背景图')
          return false
        }
        if (this.activity.id) {
          data.workbench.activityId = this.activity.id
        }
        if (this.timestype) {
          data.activity.endTime = '3022-11-08 00:00:00'
        }
        if (!data.activity.endTime) {
          this.$message.error('请选择结束时间')
          return false
        }
        if (!data.workbench.templateId) {
          data.workbench.templateId = 0
        }
        data.workbench.showTitle = data.workbench.showTitle ? 1 : 0
        data.workbench.isSame = data.workbench.isSame ? 1 : 2
        data.workbench.modifyBy = data.workbench.createBy = data.activity.modifyBy = data.activity.createBy = this.user.adminName
        data.activity.openBullet = data.activity.openBullet || data.activity.openBullet == 1 ? 1 : 2
        const res = await saveActivityWorkbench(data)
        if (res.data.success) {
          this.activity.id = res.data.activityId
          this.workbench = this.$refs.activity.workbench
          this.workbench.id = res.data.workbenchId
          this.workbench.activityId = res.data.activityId
          this.$message.success('保存成功')
          this.$nextTick(() => {
            this.$refs.activity.getinit(this.activity, this.list)
          })
        }
      } else if (this.type == 4) {
        const data = {
          result: { ...this.$refs.result.result},
          activity: { ...this.activity}
        }
        if (!this.activity.beginTime) {
          this.$message.error('请选择开始时间')
          return false
        }
        if (!data.result.bgiUrl) {
          this.$message.error('请上传背景图')
          return false
        }
        if (this.timestype) {
          data.activity.endTime = '3022-11-08 00:00:00'
        }
        if (!data.activity.endTime) {
          this.$message.error('请选择结束时间')
          return false
        }
        if (this.activity.id) {
          data.result.activityId = this.activity.id
        }
        data.result.modifyBy = data.result.createBy = data.activity.modifyBy = data.activity.createBy = this.user.adminName
        data.result.activityName = this.activity.name
        data.activity.openBullet = data.activity.openBullet || data.activity.openBullet == 1 ? 1 : 2
        const res = await saveActivityResult(data)
        if (res.data.success) {
          this.activity.id = res.data.activityId
          this.result = this.$refs.result.result
          this.result.id = res.data.resultId
          this.result.activityId = res.data.activityId
          this.$message.success('保存成功')
        }
      } else if (this.type == 5) {
        const data = {
          task: { ...this.$refs.taska.task},
          activity: { ...this.activity}
        }
        if (!this.activity.beginTime) {
          this.$message.error('请选择开始时间')
          return false
        }
        if (!data.task.bgiUrl) {
          this.$message.error('请上传背景图')
          return false
        }
        if (this.activity.id) {
          data.task.activityId = this.activity.id
        }
        if (this.timestype) {
          data.activity.endTime = '3022-11-08 00:00:00'
        }
        if (!data.activity.endTime) {
          this.$message.error('请选择结束时间')
          return false
        }
        data.task.adminId = this.user.adminId
        data.task.showTitle = data.task.showTitle ? 1 : 0
        data.task.modifyBy = data.task.createBy = data.activity.modifyBy = data.activity.createBy = this.user.adminName
        data.activity.openBullet = data.activity.openBullet || data.activity.openBullet == 1 ? 1 : 2
        const res = await saveActivityTask(data)
        if (res.data.success) {
          this.activity.id = res.data.activityId
          this.list[this.typeindex] = this.$refs.taska.task
          this.list[this.typeindex].id = res.data.taskId
          this.list[this.typeindex].activityId = res.data.activityId
          this.$message.success('保存成功')
        }
      }
    },
    sendUrl (url, key) {
      if (url) {
        switch (key) {
          case 'activity':
            this[key].imageUrl = url
            break
          default:
            break
        }
      }
    },
    importshow () {
      window.scroll(0, 0);
      this.impot = !this.impot
      if (this.impot) {
        document.querySelector('body').setAttribute('style', 'overflow: hidden;')
        this.taskName = ''
        this.getlist()
        this.getImportHistory()
      } else {
        document.querySelector('body').setAttribute('style', 'overflow: auto;')
      }
    },
    async getImportHistory () {
      const data = {
        adminId: this.user.adminId
      }
      const res = await getImportHistory(data)
      if (res.data.length > 0) {
        let list = []
        res.data.forEach((res) => {
          list.push(...res.tasks)
        })
        list.reverse()
        let array = []
        let idlist = []
        for (var i = 0; i < list.length; i++) {
          if (idlist.indexOf(list[i].id) === -1) {
            array.push(list[i])
            idlist.push(list[i].id)
          }
        }
        this.Historylist = array
      }
    },
    async getlist () {
      const data = {
        adminId: this.user.adminId,
        pageIndex: 1,
        pageSize: 200
      }
      const res = await getTaskPageList(data)
      this.tasklist = res.data.list
    },
    getimp (row) {
      this.datajs = row
      this.impotdr = true
    },
    release () {
      if (!this.activity?.id) {
        this.$message.error('请先创建活动')
        return
      }
      this.$confirm('是否发布该活动?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = {
          activityId: this.activity?.id,
          adminId: this.user.adminId
        }
        const res = await issueActivity(data)
        if (res.data) {
          if (this.activity.isStore === 1) {
            this.initActivityTaskAnswer()
          }
          this.$message({
            type: 'success',
            message: '发布成功!'
          });
          setTimeout(() => {
            this.$router.go(0)
          }, 1000);
        }
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });          
      });
    },
    async initActivityTaskAnswer () {
      const data = {
        activityId: this.activity.id
      }
      await initActivityTaskAnswer(data)
    },
    async qr (type) {
      if (type == 2) {
        if (!this.activity?.id) {
          this.$message.error('请先创建活动')
          return
        }
        const data = {
          activityId: this.activity.id,
          taskIds: [this.datajs.id],
          user: this.user.adminName,
          adminId: this.user.adminId
        }
        await importTasks(data)
        this.getActivityTasks()
      }
      this.datajs = null
      this.impotdr = false
      this.typeindex = this.list.length - 1
      this.type = 5
      let that = this
      that.$nextTick(() => {
        that.$refs.taska.getinit(that.list[this.typeindex])
      })
      this.importshow()
    },
    addlist () {
      if (this.type == 5) {
        this.type = 5
        const data = {...this.task}
        data.taskName = '新建任务' + (this.list.length+1)
        this.list.push(data)
        this.clitypetypeindex1('', this.list.length-1)
      } else {
        this.$confirm('为防信息丢失，离开页面前，请别忘记点击保存。是否确定离开?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then( () => {
          this.type = 5
          const data = {...this.task}
          data.taskName = '新建任务' + (this.list.length+1)
          this.list.push(data)
          this.clitypetypeindex1('', this.list.length-1)
        }).catch(() => {
          return false
        });
      }
    },
    getstore () {
      this.activity.isStore = 1
      this.activity.label = ''
    },
    clitypetypeindex1 (i, index) {
      if (index === this.typeindex) {
        return false
      }
      this.typeindex = index
      let that = this
      that.$nextTick(() => {
        that.$refs.taska.getinit(that.list[index], this.$refs.startup.launch.bgiUrl || this.$refs.startup.showimg, that.activity)
      })
    },
    clitypetypeindex (i, index) {
      if (index === this.typeindex) {
        return false
      }
      let that = this
      this.$confirm('为防信息丢失，离开页面前，请别忘记点击保存。是否确定离开?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then( () => {
        that.typeindex = index
        that.type = 5
        that.$nextTick(() => {
          that.$refs.taska.getinit(that.list[index], this.$refs.startup.launch.bgiUrl || this.$refs.startup.showimg, that.activity)
        })
      }).catch(() => {
        return false
      });
    }
  },
  mounted () {
    this.activity.adminId = this.user.adminId
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss'
</style>