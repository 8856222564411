import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '../views/index/index.vue'
import contactus from '../views/contactus/index.vue'
import planningcenter from '../views/planningcenter/index.vue'
import task from '../views/task/index.vue'
import activity from '../views/activity/index.vue'
import backstage from '../views/backstage/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/contactus',
    name: 'contactus',
    component: contactus
  },
  {
    path: '/planningcenter',
    name: 'planningcenter',
    component: planningcenter,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/task',
    name: 'task',
    component: task,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/activity',
    name: 'activity',
    component: activity,
    meta: {
      requireAuth: true
    }
  },
  {
    path: '/backstage',
    name: 'backstage',
    component: backstage,
    meta: {
      requireAuth: true
    }
  }
]

const router = new VueRouter({
  base: 'vtrip',
  routes
})

export default router
