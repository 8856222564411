<template>
  <div class="sb_right">
    <div class="sbr_left">
      <div class="sbrl_name">预览</div>
      <div class="sbrl_block">
        <img src="@/assets/sj_bj.png" alt="" class="sbrl_bj">
        <div class="simulation" style="z-index: 0;">
          <img :src="workbench.bgiUrl || showimg" alt="" class="simulation_bj">
        </div>
        <div class="simulation">
          <div class="tabel">
            <div class="tab_gz">活动规则</div>
            <div class="rank">排行榜</div>
          </div>
          <div class="simulation_atitle">{{ workbench.showTitle ? row?.name : ''}}</div>
          <div class="activity_time">
            活动时间： {{row?.beginTime ? row.beginTime.split('-')[1]+'月'+row.beginTime.split('-')[2].split(' ')[0]+'日'
            +' ' +row.beginTime.split('-')[2].split(' ')[1].split(':')[0] + ':' + row.beginTime.split('-')[2].split(' ')[1].split(':')[1] : ''}}-
            {{row?.endTimeStr === '3022-11-08 00:00:00' ? '长期有效' : row?.endTime ? row.endTime.split('-')[1]+'月'+row.endTime.split('-')[2].split(' ')[0]+'日'
            +' ' +row.endTime.split('-')[2].split(' ')[1].split(':')[0] + ':' + row.endTime.split('-')[2].split(' ')[1].split(':')[1] : ''}}
            
          </div>
          <!-- <div class="team_activity" v-if="row?.playMode == 2">
            <img class="tem_img" src="https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg" alt="">
            <div class="team_right">
              <div class="right_top">队伍名称</div>
              <div class="right_list">
                <img class="right_tx" src="https://vtrip.oss-cn-hangzhou.aliyuncs.com/29f9695e-cbe2-4440-9811-f20db168d922.jpg" alt="" v-for="i in 5" :key="i">
              </div>
            </div>
          </div> -->
          <div class="customs">
            <div class="forlist" v-show="right" v-for="(i,index) in showlist" :key="index">
              <div class="gkl" v-if="index%2 != 0">
                <img :src="workbench.beginIcon" class="gkll" v-if="workbench.beginIcon">
                <div class="gk_name">
                  <div class="gk_name1">{{i.taskName}}</div> 
                </div>
              </div>
              <img src="@/assets/line1.png" class="line1" v-if="index%2 != 0 && index != showlist.length - 1" />
              <div class="gkr" v-if="index%2 == 0">
                <img :src="workbench.beginIcon" class="gkll" v-if="workbench.endIcon">
                <div class="gkr_name">
                  <div class="gkr_name1">{{i.taskName}}</div> 
                </div>
              </div>
              <img src="@/assets/line2.png" class="line1" v-if="index%2 == 0 && index != showlist.length - 1" />
            </div>
            <div class="left_block" v-show="left" v-for="(i,index) in showlist" :key="index">
              <div class="left_title">
                <img :src="workbench.beginIcon" class="title_img">
                <div class="title_name">
                  <div class="title_name1">{{i.taskName}}</div> 
                </div>
              </div>
              <img src="@/assets/line5.png" class="line5" v-if="index != showlist.length - 1" />
            </div>
          </div>
          <div class="side">
            <div class="s_top">
              <img src="@/assets/chat.png" class="st_img">
              <div class="st_title">999</div>
            </div>
            <div class="s_bottom">
              <img src="@/assets/like.png" class="sb_img">
              <div class="sb_title">999</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="sbr_right">
      <div class="sbrr_name">编辑</div>
      <div class="sbrr_block">
        <div class="sbrr_title">
          【活动页】<br />活动的主界面，将完整展示活动的任务
        </div>
        <div class="sbrr_titl">背景图/音乐</div>
        <div class="sbrr_item">
          <div class="sbrri_name">选择模板图</div>
          <el-select v-model="workbench.templateId" placeholder="请选择" style="width: 190px;" @change="templ">
            <el-option :label="item.name" :value="item.id" v-for="item in list" :key="item.id"></el-option>
          </el-select>
        </div>
        <div class="sbrr_item">
          <div class="sbrri_name">自定义上传背景图</div>
          <el-upload
            style="width: 71px"
            class="avatar-uploader"
            :action="api + '/api/io/upload/'"
            :show-file-list="false"
            :on-success="handleSucces"
            :disabled="workbench.templateId > 0"
            :before-upload="beforeUpload"
            :headers="{'token': token}">
            <img v-if="workbench.bgiUrl" :src="workbench.bgiUrl" class="avatar" style="padding-top: -10px">
            <span v-else>
              <img src="@/assets/add2.png" alt="">
            </span>
          </el-upload>
          <div class="sbrr_itps">图片像素750*1624<br />图片格式建议为JPG</div>
        </div>
        <div class="sbrr_item">
          <div class="sbrri_name">上传背景音乐（可选）</div>
          <el-upload
            style="width: 71px"
            class="avatar-uploader"
            :action="api + '/api/io/upload/'"
            :show-file-list="false"
            :on-success="handleSucces1"
            :on-progress="onprogress"
            v-loading="loading"
            element-loading-text="上传中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.8)"
            :headers="{'token': token}">
            <span v-if="workbench.bgmUrl"
            :before-upload="beforeUpload">
              上传<br />
              成功
            </span>
            <span v-else>
              <img src="@/assets/music.png" alt="" style="height: 41px;">
            </span>
          </el-upload>
          <div class="sbrr_itps">建议为MP3格式</div>
        </div>
        <div class="sbrr_item" style="margin-top: 0">
          <div style="opacity: 0;"></div>
          <el-checkbox-group v-model="workbench.isSame" style="margin: 17px 0 0 37px;">
            <el-checkbox label="音乐与已启页一致">音乐与启动页一致</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="sbrr_item">
          <div class="sbrri_name">任务图标</div>
          <el-upload
            style="width: 71px"
            class="avatar-uploader"
            :action="api + '/api/io/upload/'"
            :show-file-list="false"
            :on-success="handleSucces3"
            :before-upload="beforeUpload"
            :headers="{'token': token}">
            <img v-if="workbench.beginIcon" :src="workbench.beginIcon" class="avatar" style="padding-top: -10px">
            <span v-else>
              <img src="@/assets/add3.png" alt="" style="height: 41px;">
            </span>
          </el-upload>
          <div class="sbrr_itps">未完成（暗）</div>
          <el-upload
            style="width: 71px"
            class="avatar-uploader"
            :action="api + '/api/io/upload/'"
            :show-file-list="false"
            :on-success="handleSucces4"
            :headers="{'token': token}">
            <img v-if="workbench.endIcon" :src="workbench.endIcon" class="avatar" style="padding-top: -10px">
            <span v-else>
              <img src="@/assets/add3.png" alt="" style="height: 41px;">
            </span>
          </el-upload>
          <div class="sbrr_itps">已完成（亮）</div>
        </div>
        <div class="sbrr_item">
          <div style="opacity: 0;"></div>
          <div class="sbrr_itps">图片像素100*100<br />图片格式为PNG</div>
        </div>
        <div class="sbrr_item">
          <div class="sbrri_name">任务排列方式</div>
          <el-checkbox-group v-model="left" style="margin: 0 0 0 37px;" @change="leftclit">
            <el-checkbox label="左对齐">左对齐</el-checkbox>
          </el-checkbox-group>
        </div>
        <div class="sbrr_item" style="margin-top: 0">
          <div style="opacity: 0;" class="sbrri_name"></div>
          <el-checkbox-group v-model="right" style="margin: 17px 0 0 37px;" @change="rgitlit">
            <el-checkbox label="左右间隔">左右间隔</el-checkbox>
          </el-checkbox-group>
        </div>
        <el-checkbox-group v-model="workbench.showTitle" style="margin: 17px 0 0 37px;">
          <el-checkbox label="显示标题">显示标题</el-checkbox>
        </el-checkbox-group>
      </div>
    </div>
  </div>
</template>

<script>
import { getWorkbenchByActivity, api, getTemplates } from '@/api/api'
import base64 from '@/utils/base64';
export default {
  name: 'StartupView',
  data() {
    return {
      token: new Date().getTime()+';'+base64.accumulation(new Date().getTime()),
      checkedCities: [],
      workbench: {
        activityId: 0,
        beginIcon: '',
        bgiUrl: '',
        bgmUrl: '',
        bgvUrl: '',
        bulletNum: 0,
        createBy: '',
        endIcon: '',
        id: 0,
        isSame: 0,
        likeNum: 0,
        modifyBy: '',
        remark: '',
        showTitle: 1,
        sortType: 0,
        templateId: 20
      },
      left: false,
      right: false,
      api: api,
      list: [],
      showimg: '',
      loading: false,
      row: null,
      showlist: []
    }
  },
  methods: {
    beforeUpload (file) {
      const isLt2M = file.size / 1024 / 1024 < 50;
      if (!isLt2M) {
        this.$message.error('上传大小不能超过 50MB!');
      }
      return isLt2M;
    },
    onprogress () {
      this.loading = true
    },
    handleSucces (res) {
      this.workbench.bgiUrl = res
      this.$message.success('上传成功')
    },
    handleSucces1 (res) {
      this.workbench.bgmUrl = res
      this.loading = false
      this.$message.success('上传成功')
    },
    handleSucces3 (res) {
      this.workbench.beginIcon = res
      this.$message.success('上传成功')
    },
    handleSucces4 (res) {
      this.workbench.endIcon = res
      this.$message.success('上传成功')
    },
    leftclit (e) {
      this.left = e
      this.right = !e
      this.workbench.sortType = 1
    },
    rgitlit (e) {
      this.left = !e
      this.right = e
      this.workbench.sortType = 2
    },
    async getTemplates() {
      const data = {
        templateType: 1
      }
      const res = await getTemplates(data)
      this.list = [{id: 0,name: "自定义"},...res.data]
    },
    templ () {
      this.workbench.bgiUrl = ''
      if (this.workbench.templateId) {
        this.list.forEach((res)=>{
          if (res.id === this.workbench.templateId) {
            this.showimg = res.imageUrl
          }
        })
      } else {
        this.showimg = ''
      }
      this.$message.info('保存后显示默认图标')
    },
    async getinit (row, showlist = []) {
      await this.getTemplates()
      let datarow  = {
        activityId: 0,
        beginIcon: '',
        bgiUrl: '',
        bgmUrl: '',
        bgvUrl: '',
        bulletNum: 0,
        createBy: '',
        endIcon: '',
        id: 0,
        isSame: 0,
        likeNum: 0,
        modifyBy: '',
        remark: '',
        showTitle: 1,
        sortType: 0,
        templateId: 20
      }
      this.row = row
      this.showlist = showlist
      if (row.id) {
        const data = {
          activityId: row.id
        }
        const res = await getWorkbenchByActivity(data)
        if (res.data) {
          datarow = res.data
        }
      }
      datarow.showTitle = datarow.showTitle == 1 ? true : false
      datarow.isSame = datarow.isSame == 1 ? true : false
      this.workbench = { ...datarow }
      if (this.workbench.sortType == 1) {
        this.leftclit(true)
      } else {
        this.rgitlit(true)
      }
      if (this.workbench.templateId) {
        this.templ()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss'
</style>
