<template>
  <div class="index">
    <HeaderView :xzindex="4"></HeaderView>
    <div class="task" v-if="!show">
      <div class="list_block">
        <div class="name">所有活动</div>
        <div class="sreach">
          <el-input placeholder="输入活动关键词" @change="page = 1,getlist()" class="sreach_input" suffix-icon="el-icon-search" v-model="activityName"></el-input>
        </div>
        <el-radio-group v-model="type" style="margin-top: 30px" @input="getype">
          <el-radio-button :label="1">已发布的活动</el-radio-button>
          <el-radio-button :label="2">活动库</el-radio-button>
        </el-radio-group>
        <div class="tab" v-if="type == 1">
          <div class="t_item" @click="dataTypec(1)">最新发布</div>
          <div class="t_item" @click="dataTypec(2)">最多人数</div>
          <div class="t_item" @click="dataTypec(3)">最多图片</div>
          <div class="t_item" @click="dataTypec(4)">最多视频</div>
          <div class="t_item" @click="dataTypec(5)">最多弹幕</div>
        </div>
        <el-table :data="list" style="width: 100%;margin-top: 32px;">
          <el-table-column prop="id" label="ID" show-overflow-tooltip align="center">
          </el-table-column>
          <el-table-column prop="name" show-overflow-tooltip label="名称" align="center">
          </el-table-column>
          <el-table-column prop="modifyOn" show-overflow-tooltip align="center" label="修改时间">
            <template slot-scope="scope">
              {{ scope.row.modifyOn !== '0001-01-01 00:00:00' ? scope.row.modifyOn : '' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="400" align="center">
            <template slot-scope="scope">
              <div class="tab1">
                <div @click="addactivity('examine', scope.row)" v-if="type == 1">审核</div>
                <div @click="copy(scope.row)" v-if="type == 1">复制</div>
                <div @click="gotodeil(scope.row)" v-if="type == 2 && scope.row.status != 2">编辑</div>
                <div @click="addactivity('data', scope.row)" v-if="type == 1">数据</div>
                <div @click="deleteis(scope.row)">删除</div>
                <div @click="release(scope.row)" v-if="type == 2 && scope.row.status != 2">发布</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            small
            layout="prev, pager, next"
            :total="total"
            :current-page="page"
            @current-change="gotopage">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="task">
      <examine v-show="show == 'examine'" ref="examine"></examine>
      <datanum v-show="show == 'data'" ref="datanum"></datanum>
      <startup v-show="show == 'startup'" ref="startup"></startup>
    </div>
  </div>
</template>

<script>
import { getPublishActivityPageList, deleteActivity, getActivityStore, issueActivity, copyActivity, initActivityTaskAnswer } from '@/api/api'
import HeaderView from '@/components/header/HeaderView.vue'
import startup from '../planningcenter/components/startup/index.vue'
import examine from '../planningcenter/components/examine/index.vue'
import datanum from '../planningcenter/components/datanum/index.vue'
export default {
  name: 'BackstageView',
  components: {
    HeaderView,
    startup,
    examine,
    datanum
  },
  data() {
    return {
      show: '',
      list: [],
      activityName: '',
      dataType: 1,
      page: 1,
      total: 0,
      type: 1
    }
  },
  mounted () {
    this.getlist()
  },
  computed: {
    user () {
      return this.$store.getters.user
    }
  },
  methods: {
    copy (row) {
      this.$prompt('请输入新名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: '',
        inputErrorMessage: ''
      }).then(async ({ value }) => {
        const data = {
          copyType: 2,
          adminId: this.user.adminId,
          activityName: value,
          activityId: row.id
        }
        const res = await copyActivity(data)
        if (res.data) {
          this.$message({
            type: 'success',
            message: '复制成功!'
          });
        }
      }).catch(() => {
             
      });
    },
    release (row) {
      this.$confirm('是否发布该活动?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = {
          activityId: row.id,
          adminId: this.user.adminId
        }
        const res = await issueActivity(data)
        if (res.data) {
          this.$message({
            type: 'success',
            message: '发布成功!'
          });
          this.initActivityTaskAnswer(row.id)
          this.getlist()
        }
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });          
      });
    },
    async initActivityTaskAnswer (id) {
      const data = {
        activityId: id
      }
      await initActivityTaskAnswer(data)
    },
    getype () {
      this.page = 1
      this.list= []
      this.total = 0
      this.getlist()
    },
    deleteis (row) {
      this.$confirm('此操作将永久删除该活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = {
          entityId: row.id,
          operator: this.user.adminName
        }
        const res = await deleteActivity(data)
        if (res.data) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.page = 1
          this.getlist()
        }
      }).catch(() => {
      });
    },
    gotodeil (row) {
      let that = this
      that.show = 'startup'
      that.$nextTick(() => {
        that.$refs.startup.getinit(row.id)
      })
    },
    addactivity (type, row) {
      if (this.show == type) {
        return false
      }
      if (type == 'startup') {
        this.$nextTick(() => {
          this.$refs.startup.getinit()
        })
      }
      if (type == 'examine') {
        this.$nextTick(() => {
          this.$refs.examine.getinit(row.id, row.name)
        })
      }
      if (type == 'data') {
        this.$nextTick(() => {
          this.$refs.datanum.getinit(row.id, row.name)
        })
      }
      this.show = type
    },
    gotopage (e) {
      this.page = e
      this.getlist()
    },
    dataTypec (e) {
      this.dataType = e
      this.list = []
      this.page = 1
      this.getlist()
    },
    async getlist () {
      if (this.type == 1) {
        const data = {
          activityName: this.activityName,
          dataType: this.dataType,
          taskName: this.input,
          pageIndex: this.page,
          pageSize: 10
        }
        const res = await getPublishActivityPageList(data)
        this.list= res.data.list
        this.total = res.data.total
      } else {
        const data = {
          activityName: this.activityName,
          adminId: 0,
          status: 3,
          pageIndex: this.page,
          pageSize: 10
        }
        const res = await getActivityStore(data)
        this.list= res.data.list
        this.total = res.data.total
      }
      
    },
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss'
</style>
