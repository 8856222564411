<template>
  <div class="index">
    <HeaderView :xzindex="2"></HeaderView>
    <div class="block" v-if="!taskshow">
      <div class="top" v-if="!show">
        <div class="add" @click="addactivity('startup')">
          <img src="@/assets/add.png" alt="" class="add_img">
          新建活动
        </div>
        <div class="add" @click="addtask">
          <img src="@/assets/add.png" alt="" class="add_img">
          新建任务
        </div>
        <div class="add" @click="gotoactivity">
          看模板，找灵感
        </div>
      </div>
      <div v-if="!show">
        <div class="list_top">策划中的活动</div>
        <el-table :data="list" :header-cell-style="myHeader" height="140" style="width: 100%;margin-top: 18px;">
          <el-table-column prop="id" label="ID" show-overflow-tooltip align="center">
          </el-table-column>
          <el-table-column prop="name" show-overflow-tooltip label="名称" align="center">
          </el-table-column>
          <el-table-column prop="modifyOn" show-overflow-tooltip align="center" label="修改时间">
            <template slot-scope="scope">
              {{ scope.row.modifyOn !== '0001-01-01 00:00:00' ? scope.row.modifyOn : '' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="540" align="center">
            <template slot-scope="scope">
              <div class="tab">
                <div @click="gotodeil(scope.row)">编辑</div>
                <div @click="release(scope.row)">发布</div>
                <div @click="copy1(scope.row)">复制</div>
                <div @click="deleteis(scope.row)">删除</div>
                <div @click="down(scope.row)">任务二维码</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            small
            layout="prev, pager, next"
            :total="total"
            :current-page="page"
            @current-change="gotopage">
          </el-pagination>
        </div>
        <div class="list_top">已发布的活动</div>
        <el-table :data="list1" :header-cell-style="myHeader" height="140" style="width: 100%;margin-top: 18px;">
          <el-table-column prop="id" label="ID" show-overflow-tooltip align="center">
          </el-table-column>
          <el-table-column prop="name" show-overflow-tooltip label="名称" align="center">
          </el-table-column>
          <el-table-column prop="modifyOn" show-overflow-tooltip align="center" label="状态">
            <template slot-scope="scope">
              {{ scope.row.status == 2 ? '进行中' : '已下线' }}
            </template>
          </el-table-column>
          <el-table-column prop="modifyOn" show-overflow-tooltip align="center" label="发布时间">
            <template slot-scope="scope">
              {{ scope.row.modifyOn !== '0001-01-01 00:00:00' ? scope.row.modifyOn : '' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="640" align="center">
            <template slot-scope="scope">
              <div class="tab">
                <div v-if="(scope.row.status == 2)" @click="showcode(scope.row)">二维码</div>
                <div v-if="(scope.row.status == 2)" @click="addactivity('examine', scope.row)">审核</div>
                <div @click="copy1(scope.row)">复制</div>
                <div @click="addactivity('data', scope.row)">数据</div>
                <div v-if="(scope.row.status == 2)" @click="offlineActivity(scope.row)">下线</div>
                <div @click="down(scope.row)">任务二维码</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            small
            layout="prev, pager, next"
            :total="total1"
            :current-page="page1"
            @current-change="gotopage1">
          </el-pagination>
        </div>
        <div class="list_top">我的任务</div>
        <el-table :data="list2" :header-cell-style="myHeader" height="140" style="width: 100%;margin-top: 18px;">
          <el-table-column prop="id" label="ID" show-overflow-tooltip align="center">
          </el-table-column>
          <el-table-column prop="taskName" show-overflow-tooltip label="名称" align="center">
          </el-table-column>
          <el-table-column prop="modifyOn" show-overflow-tooltip align="center" label="创建时间">
            <template slot-scope="scope">
              {{ scope.row.modifyOn !== '0001-01-01 00:00:00' ? scope.row.modifyOn : '' }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="300" align="center">
            <template slot-scope="scope">
              <div class="tab">
                <div @click="edit(scope.row)">编辑</div>
                <div @click="copy(scope.row)">复制</div>
                <div @click="deleteis2(scope.row)">删除</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
            small
            layout="prev, pager, next"
            :total="total2"
            :current-page="page2"
            @current-change="gotopage2">
          </el-pagination>
        </div>
      </div>
      <startup v-show="show == 'startup'" ref="startup"></startup>
    </div>
    <examine v-show="show == 'examine'" ref="examine"></examine>
    <datanum v-show="show == 'data'" ref="datanum"></datanum>
    <div class="block">
      <task v-show="taskshow" ref="task"></task>
    </div>
    <el-dialog
      title="二维码"
      :visible.sync="codeshow"
      width="30%">
      <div style="width: 200px;margin: 0 auto;">
        <img :src="code" alt="" style="width: 200px;height: 200px;">
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="codeshow = false,code = ''">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import HeaderView from '@/components/header/HeaderView.vue'
import { getActivityPageList, down, getTaskPageList, deleteActivityTask, offlineActivity, copyTask, copyActivity, deleteActivity, issueActivity, getQRCode } from '@/api/api'
import startup from './components/startup/index.vue'
import examine from './components/examine/index.vue'
import datanum from './components/datanum/index.vue'
import task from '@/components/task/index.vue'
export default {
  name: 'PlanningcenterView',
  components: {
    startup,
    examine,
    datanum,
    HeaderView,
    task
  },
  computed: {
    user () {
      return this.$store.getters.user
    }
  },
  data() {
    return {
      list: [],
      list1: [],
      list2: [],
      total: 0,
      total1: 0,
      total2: 0,
      myHeader: {
        backgroundColor: '#E1E4E8',
        height: '35px'
      },
      show: false,
      page: 1,
      page1: 1,
      page2: 1,
      name: '',
      taskshow: false,
      code: '',
      codeshow: false
    }
  },
  methods: {
    async down (row) {
      const data = {
        activityId: row.id
      }
      const res = await down(data)
      if (res.data) {
        // const link = document.createElement('a')
        // link.style.display = 'none'
        // link.href = 'https://api.welvcheng.com/api/io/download/local/' + res.data
        // link.setAttribute('download', res.data)
        // document.body.appendChild(link)
        // link.click()
        // document.body.removeChild(link)
        // let index = res.data.length
        // this.tid = setInterval(() => {
        //   let i = --index
        //   if (index > -1) {
        //     const link = document.createElement('a')
        //     link.style.display = 'none'
        //     link.href = res.data[i]
        //     link.setAttribute('download', res.data[i])
        //     document.body.appendChild(link)
        //     link.click()
        //     document.body.removeChild(link)
        //   } else {
        //     clearInterval(this.tid)
        //   }
        // }, 500)
        window.open('https://api.welvcheng.com/api/io/download/local/' + res.data)
      } else {
        this.$message.error('暂无二维码可下载')
      }
    },
    async showcode (row) {
      const data = {
        path: 'pages/startup/startup',
        scene: row.id
      }
      const res = await getQRCode(data)
      if (res.code == 0) {
        this.codeshow = true
        this.code = res.data
      }
    },
    gotoactivity () {
      this.$router.push({
        name: 'activity'
      })
    },
    gotopage (e) {
      this.page1 = e
      this.getActivityPageList(1)
    },
    gotopage1 (e) {
      this.page = e
      this.getActivityPageList(4)
    },
    gotopage2 (e) {
      this.page2 = e
      this.getTaskPageList()
    },
    gotodeil (row) {
      let that = this
      that.show = 'startup'
      that.$nextTick(() => {
        that.$refs.startup.getinit(row.id)
      })
    },
    edit (row) {
      let that = this
      that.taskshow = true
      that.$nextTick(() => {
        that.$refs.task.getinit(row.id)
      })
    },
    addtask () {
      let that = this
      that.taskshow = true
      that.$nextTick(() => {
        that.$refs.task.getinit()
      })
    },
    getinit() {
      this.getActivityPageList(4)
      this.getActivityPageList(1)
      this.getTaskPageList()
    },
    async getActivityPageList (type) {
      const data = {
        activityType: type,
        adminId: this.user.adminId,
        pageIndex: type == 4 ? this.page : this.page1,
        pageSize: 10,
        name: this.name
      }
      const res = await getActivityPageList(data)
      if (type == 1) {
        this.list = res.data.list
        this.total = res.data.total
      } else {
        this.list1 = res.data.list
        this.total1 = res.data.total
      }
    },
    async getTaskPageList () {
      const data = {
        adminId: this.user.adminId,
        pageIndex: this.page2,
        pageSize: 10
      }
      const res = await getTaskPageList(data)
      this.list2 = res.data.list
      this.total2 = res.data.total
    },
    addactivity (type, row) {
      if (this.show == type) {
        return false
      }
      if (type == 'startup') {
        this.$nextTick(() => {
          this.$refs.startup.getinit()
        })
      }
      if (type == 'examine') {
        this.$nextTick(() => {
          this.$refs.examine.getinit(row.id, row.name)
        })
      }
      if (type == 'data') {
        this.$nextTick(() => {
          this.$refs.datanum.getinit(row.id, row.name)
        })
      }
      this.show = type
    },
    copy (row) {
      this.$prompt('请输入新名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: '',
        inputErrorMessage: ''
      }).then(async ({ value }) => {
        const data = {
          adminId: this.user.adminId,
          newTaskName: value,
          oriTaskId: row.id
        }
        const res = await copyTask(data)
        if (res.data) {
          this.$message({
            type: 'success',
            message: '复制成功!'
          });
          this.getTaskPageList()
        }
      }).catch(() => {
             
      });
    },
    copy1 (row) {
      this.$prompt('请输入新名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: '',
        inputErrorMessage: ''
      }).then(async ({ value }) => {
        const data = {
          adminId: this.user.adminId,
          activityName: value,
          activityId: row.id
        }
        const res = await copyActivity(data)
        if (res.data) {
          this.$message({
            type: 'success',
            message: '复制成功!'
          });
          this.getActivityPageList(4)
          this.getActivityPageList(1)
        }
      }).catch(() => {
             
      });
    },
    offlineActivity (row) {
      this.$confirm('此操作将下线该活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = {
          activityId: row.id,
          operator: this.user.adminName
        }
        const res = await offlineActivity(data)
        if (res.data) {
          this.$message({
            type: 'success',
            message: '下线成功!'
          });
          this.page = 1
          this.getActivityPageList(4)
        }
      }).catch(() => {
      });
    },
    deleteis (row) {
      this.$confirm('此操作将永久删除该活动, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = {
          entityId: row.id,
          operator: this.user.adminName
        }
        const res = await deleteActivity(data)
        if (res.data) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.page1 = 1
          this.getActivityPageList(1)
        }
      }).catch(() => {
      });
    },
    deleteis2 (row) {
      this.$confirm('此操作将永久删除该任务, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = {
          entityId: row.id,
          operator: this.user.adminName
        }
        const res = await deleteActivityTask(data)
        if (res.data) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.page2 = 1
          this.getTaskPageList()
        }
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });          
      });
    },
    release (row) {
      this.$confirm('是否发布该活动?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = {
          activityId: row.id,
          adminId: this.user.adminId
        }
        const res = await issueActivity(data)
        if (res.data) {
          this.$message({
            type: 'success',
            message: '发布成功!'
          });
          this.page = 1
          this.page1 = 1
          this.getActivityPageList(4)
          this.getActivityPageList(1)
        }
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消删除'
        // });          
      });
    }
  },
  mounted () {
    this.getinit()
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss'
</style>
