<template>
  <div class="index">
    <div class="search" style="width: 1200px; margin: 0 auto;">
      <div class="activity_id">活动ID：{{id}}</div>
      <div class="activity_name">活动名称：{{name}}</div>
    </div>
    <div class="top">
      <div class="top_block">
        <div :class="zxindex === index ? 'item active' : 'item'" v-for="(i,index) in list" :key="index" @click="getop(index)">{{i}}</div>
      </div>
    </div>
    <div class="block" v-if="zxindex === 0 || zxindex === 1">
      <div class="title">
        <div class="title_left">
          <div :class="titleindex === index ? 'active' : ''" v-for="(i,index) in titlelist" :key="index" @click="getitle(index)">{{i.taskName}}</div>
        </div>
        <div class="title_right" @click="plclicklist()">
          批量通过
        </div>
      </div>
      <div class="list" v-infinite-scroll="load">
        <div class="item" v-for="item in tablis1t" :key="item.id" @click="clicklist(item)">
          <img :src="item.answer" alt="" class="item_img" v-if="item.answerMode == 4" @dblclick.stop="look(1, item.answer)">
          <video :src="item.answer" alt="" class="item_img" v-if="item.answerMode == 3" @dblclick.stop="look(2, item.answer)" />
          <div class="item_bottom">
            <div :class="idlist.indexOf(item.id) !== -1 ? 'active' : ''" @click.stop="determine(item)">
              <img src="@/assets/correct.png" alt="" v-if="idlist.indexOf(item.id) !== -1">
              <img src="@/assets/correcth.png" alt="" v-else>
            </div>
            <div @click.stop="expcancel(item)">
              <img src="@/assets/error.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="block" v-if="zxindex === 2">
      <div class="search">
        <el-input v-model="comment" @change="typeselect2" placeholder="'搜索弹幕" class="search_id" suffix-icon="el-icon-search" style="margin-right: 20px"></el-input>
        <el-input v-model="createBy" @change="typeselect2" placeholder="'搜索用户" class="search_id" suffix-icon="el-icon-search" style="margin-right: 20px"></el-input>
      </div>
      <div class="title" style="justify-content: flex-end;">
        <div class="title_right" @click="batchPassBullet">
          批量通过
        </div>
      </div>
      <el-table :data="tablist" style="width: 100%;margin-top: 18px;">
        <el-table-column prop="comment" show-overflow-tooltip label="弹幕内容" align="center">
        </el-table-column>
        <el-table-column prop="createBy" show-overflow-tooltip label="用户昵称" align="center">
        </el-table-column>
        <el-table-column label="操作" width="300" align="center">
          <template slot-scope="scope">
            <div class="tab">
              <div @click="batchPassBulle(scope.row)">通过</div>
              <div @click="deleteActivityBullet(scope.row)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          small
          layout="total, prev, pager, next, jumper"
          :total="total"
          :current-page="page"
          @current-change="gotopage">
        </el-pagination>
      </div>
    </div>
    <div class="block" v-if="zxindex === 3">
      <div class="search">
        <el-input v-model="nickName" @change="typeselect1" placeholder="'搜索组名" class="search_id" suffix-icon="el-icon-search" style="margin-right: 20px"></el-input>
        <el-input v-model="username" @change="typeselect1" placeholder="'搜索组员" class="search_id" suffix-icon="el-icon-search" style="margin-right: 20px"></el-input>
      </div>
      <div class="title" style="justify-content: flex-end;">
        <div class="title_right">
          批量通过
        </div>
      </div>
      <el-table :data="userlist" style="width: 100%;margin-top: 18px;">
        <el-table-column prop="teamName" show-overflow-tooltip label="组名" align="center">
        </el-table-column>
        <el-table-column prop="members" show-overflow-tooltip label="组员" align="center">
        </el-table-column>
        <el-table-column label="操作" width="300" align="center">
          <template slot-scope="scope">
            <div class="tab">
              <div @click="block(scope.row)">拉黑</div>
              <div @click="deleteuser(scope.row)">删除</div>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :total="usertotal"
          :current-page="userpage"
          @current-change="gotopage1">
        </el-pagination>
      </div>
    </div>
    <preview :isimg="isimg" :url="url" :isvideo="isvideo" ref="preview"></preview>
  </div>
</template>

<script>
import { getPageActivityBullets, batchPassBullet, getTaskAuditAnswers, blackTeam, batchConfirmTaskResult, getActivityTasks, deleteActivityBullet, getPageActivityTeamMembers, deleteActivityTeam } from '@/api/api'
import preview from '@/components/preview/index.vue'
export default {
  name: 'ExamineView',
  components: {
    preview
  },
  data() {
    return {
      list: ['图片审核','视频审核','弹幕审核','用户审核'],
      zxindex: 0,
      input: '',
      titlelist: [],
      titleindex: 0,
      listindex: [],
      tablist: [],
      userlist: [],
      id: '',
      page: 1,
      total: 0,
      nickName: '',
      name: '',
      type: 1,
      usertotal: 0,
      userpage: 1,
      tablis1t: [],
      taskId: 0,
      page2 : 1,
      idlist: [],
      isimg: false,
      isvideo: false,
      url: '',
      username: '',
      comment: '',
      createBy: ''
    }
  },
  computed: {
    user () {
      return this.$store.getters.user
    }
  },
  methods: {
    look (type, url) {
      if (type == 1) {
        this.isimg = true
        this.isvideo = false
      } else {
        this.isvideo = true
        this.isimg = false
      }
      this.url = url
      this.$refs['preview'].show = true
    },
    gotopage (e) {
      this.page = e
      this.getPageActivityBullets()
    },
    gotopage1 (e) {
      this.userpage = e
      this.getuser()
    },
    async batchPassBulle (row) {
      const data = {
        bulletIds: [row.id],
        user: this.user.adminName
      }
      const res = await batchPassBullet(data)
      if (res.data) {
        this.$message.success('审核成功')
        this.page = 1
        this.tablist = []
        this.getPageActivityBullets()
      } else {
        this.$message.error('审核失败')
      }
    },
    async batchPassBullet () {
      if (this.tablist.length == 0) {
        this.$message.error('没有需要审核的弹幕')
        return false
      }
      let list = []
      this.tablist.forEach((res) => {
        list.push(res.id)
      })
      const data = {
        bulletIds: list,
        user: this.user.adminName
      }
      const res = await batchPassBullet(data)
      if (res.data) {
        this.$message.success('审核成功')
        this.page = 1
        this.tablist = []
        this.getPageActivityBullets()
      } else {
        this.$message.error('审核失败')
      }
    },
    load () {
      this.page2++
      this.getTeamTaskAuditAnswers()
    },
    async determine (row) {
      const data = {
        activityId: this.id,
        answers: [
          {
            answerId: row.id,
            isRight: 1
          }
        ],
        user: this.user.adminName
      }
      const res = await batchConfirmTaskResult(data)
      if (res.data) {
        this.$message.success('审核成功')
        this.page2 = 1
        this.tablis1t = []
        this.idlist = []
        this.getTeamTaskAuditAnswers()
      } else {
        this.$message.error('审核失败')
      }
    },
    async plclicklist () {
      if (this.idlist.length == 0) {
        this.$message.error('请选择答案')
        return false
      }
      let list = []
      this.idlist.forEach((res) => {
        list.push({answerId: res,isRight: 1})
      })
      const data = {
        activityId: this.id,
        answers: list,
        user: this.user.adminName
      }
      const res = await batchConfirmTaskResult(data)
      if (res.data) {
        this.$message.success('审核成功')
        this.page2 = 1
        this.idlist = []
        this.tablis1t = []
        this.getTeamTaskAuditAnswers()
      } else {
        this.$message.error('审核失败')
      }
    },
    async expcancel (row) {
      const data = {
        activityId: this.id,
        answers: [
          {
            answerId: row.id,
            isRight: 3
          }
        ],
        user: this.user.adminName
      }
      const res = await batchConfirmTaskResult(data)
      if (res.data) {
        this.$message.success('审核成功')
        this.page2 = 1
        this.idlist = []
        this.tablis1t = []
        this.getTeamTaskAuditAnswers()
      } else {
        this.$message.error('审核失败')
      }
    },
    clicklist (row) {
      if (this.idlist.indexOf(row.id) === -1) {
        this.idlist.push(row.id)
      } else {
        this.idlist.splice(this.idlist.indexOf(row.id), 1)
      }
    },
    async getTeamTaskAuditAnswers () {
      const data = {
        activityId: this.id,
        answerMode: this.zxindex == 1 ? 3 : 4,
        pageIndex: this.page2,
        pageSize: 10,
        taskId: this.taskId
      }
      const res = await getTaskAuditAnswers(data)
      this.tablis1t = [...this.tablis1t, ...res.data.list]
    },
    async getActivityTasks () {
      const data = {
        activityId: this.id
      }
      const res = await getActivityTasks(data)
      let list = []
      res.data.forEach((res) => {
        if (this.zxindex == 1 && res.answerMode == 3) {
          list.push(res)
        } else if (this.zxindex == 0 && res.answerMode == 4) {
          list.push(res)
        }
      })
      this.titlelist = [{taskName: '全部任务',id: 0},...list]
    },
    deleteActivityBullet (row) {
      this.$confirm('此操作将永久删除该弹幕, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = {
          entityId: row.id,
          operator: this.user.adminName
        }
        const res = await deleteActivityBullet(data)
        if (res.data) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.page = 1
          this.getPageActivityBullets()
        }
      }).catch(() => {
      });
    },
    block (row) {
      this.$confirm('此操作将永久拉黑该组用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = {
          teamId: row.teamId,
          user: this.user.adminName
        }
        const res = await blackTeam(data)
        if (res.data) {
          this.$message({
            type: 'success',
            message: '拉黑成功!'
          });
          this.typeselect()
        } else {
          this.$message.error('拉黑失败')
        }
      }).catch(() => {
      });
    },
    deleteuser (row) {
      this.$confirm('此操作将永久删除该组用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const data = {
          entityId: row.teamId,
          operator: this.user.adminName
        }
        const res = await deleteActivityTeam(data)
        if (res.data) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
          this.typeselect()
        }
      }).catch(() => {
      });
    },
    getinit (id, name = "活动名称") {
      this.id = id
      this.name = name
      this.zxindex = 0
      this.page2 = 1
      this.getTeamTaskAuditAnswers()
      this.getActivityTasks()
    },
    getop (index) {
      if (index === this.zxindex) {
        return false
      }
      this.zxindex = index
      if (index == 0 || index == 1) {
        this.tablis1t = []
        this.idlist = []
        this.page2 = 1
        this.getActivityTasks()
        this.getTeamTaskAuditAnswers()
      }
      if (index == 2) {
        this.page = 1
        this.getPageActivityBullets()
      }
      if (index == 3) {
        this.userpage = 1
        this.nickName = ''
        this.userlist = []
        this.usertotal = 0
        this.getuser()
      }
    },
    typeselect () {
      this.userpage = 1
      this.nickName = ''
      this.username = ''
      this.userlist = []
      this.usertotal = 0
      this.getuser()
    },
    typeselect1 () {
      this.userpage = 1
      this.userlist = []
      this.usertotal = 0
      this.getuser()
    },
    typeselect2 () {
      this.page = 1
      this.tablist = []
      this.total = 0
      this.getPageActivityBullets()
    },
    async getuser () {
      const data = {
        activityId: this.id,
        pageIndex: this.userpage,
        pageSize: 10,
        teamName: this.nickName,
        nickName: this.username
      }
      const res = await getPageActivityTeamMembers(data)
      this.userlist = res.data.list
      this.usertotal = res.data.total
    },
    async getPageActivityBullets () {
      const data = {
        activityId: this.id,
        pageIndex: this.page,
        pageSize: 10,
        comment: this.comment,
        createBy: this.createBy
      }
      const res = await getPageActivityBullets(data)
      this.tablist = res.data.list
      this.total = res.data.total
    },
    getitle (index) {
      if (index === this.titleindex) {
        return false
      }
      this.titleindex = index
      this.taskId = this.titlelist[index].id
      this.page2 = 1
      this.idlist = []
      this.tablis1t = []
      this.getTeamTaskAuditAnswers()
    },
    getlistshow (index) {
      if (this.listindex.indexOf(index) !== -1) {
        return false
      }
      this.listindex.push(index)
    }
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss'
</style>
