import axios from 'axios'
import { Message } from 'element-ui'
import base64 from '@/utils/base64';
const apiAxios = axios.create({
  baseURL: '/',
  timeout: 120000,
})

apiAxios.interceptors.request.use(function (config) {
  config.headers['token'] = new Date().getTime()+';'+base64.accumulation(new Date().getTime())
  return config;  
});

apiAxios.interceptors.response.use(function (response) {
  //关闭进度条
  if (response.data.code == '1003') {
    Message.warning(response.data.message)
    return
  }
  return response.data;
}, error => {
  if (error.response) {
    if (isString(error.response.data)) {
      Message({
        message: error.response.status + ':' + error.response.statusText,
        type: 'error',
        duration: 2000
      })
    } else {
      Message({
        message: error.response.data.message,
        type: 'error',
        duration: 2000
      })
    }
  } else {
    Message({
      message: error.message,
      type: 'error',
      duration: 2000
    })
  }
  if (error) {
    // const { response } = error
    // if(response.status){
    //   if(response.status === 401){
    //     window.location.href('/login')
    //   }
    // }
  }
  //关闭进度条

  // if (error.message!='请不要重复提交！') {
  //     requestMap.delete(lastUniqueCode)
  //     //如果api为404时候也需要删除对应UniqueCode
  // }
  return Promise.reject(error);

});

function isString(obj) {
  return Object.prototype.toString.call(obj) === "[object String]"
}

export default apiAxios
