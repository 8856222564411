<template>
  <div class="index">
    <HeaderView :xzindex="1"></HeaderView>
    <div class="block" v-if="!show">
      <div class="right">
        <div class="right_top">
          <div class="rt_left">
          </div>
          <div class="rt_righ">
            <div style="width: 291px;">
              <el-input v-model="input" class="rt_input" placeholder="输入活动关键词" suffix-icon="el-icon-search" @change="inputsearch()"></el-input>
            </div>
            <div class="rt_right" @click="addactivity('startup')" v-if="user.isAdmin == 1">
              <img src="@/assets/add.png" alt="">新建活动
            </div>
          </div>
        </div>
        <div class="list" v-infinite-scroll="load">
          <div class="item" v-for="i in list" :key="i.id" @click="select(i)">
            <div class="title">{{i.name}}</div>
            <div class="desc">{{i.introduction}}</div>
            <div class="list_bottom">
              <div class="label">
                <div class="litem" v-for="(it, index) in i.label" :key="index">{{it}}</div>
              </div>
              <div class="list_practice">点击体验</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="detailedshow"
      width="50%">
      <div class="dialog">
        <div class="dialog_title">{{xzdata.name}}</div>
        <div class="dialog_desc">{{xzdata.introduction}}</div>
        <div class="dialog_block">
          <div class="label">
            <div class="litem" v-for="(it, index) in xzdata.label" :key="index">{{it}}</div>
          </div>
          <div class="list_practice" @click="copy">复制到我的活动</div>
        </div>
        <div class="dialog_code">
          <img :src="code">
        </div>
        <div class="dialog_explain">微信扫码体验</div>
        <div class="dialog_consult">体验说明:</div>
        <div class="dialog_desc">
          活动库所有的体验活动中，所有需要上传图片和上传视频的任务，请上传体验图片 (<span class="dialog_download1" @click="down('https://vtrip.oss-cn-hangzhou.aliyuncs.com/a542d397-447f-4388-aa8c-90d3e3c1519b.jpg')">下载</span>)和体验视频 (<span class="dialog_download1" @click="down('https://vtrip.oss-cn-hangzhou.aliyuncs.com/ea6d9bb2-3565-432f-90bd-2fa791596002.mp4')">下载</span>) ，任务将自动通过，上传其他图片或视频则不通过。
        </div>
        <div class="dialog_desc">体验图片、体验视频、二维码需要下载后同步到手机，手机获取方式: 关注微信公众号[We旅程]，回复关键词: 体验图片、体验视频即可获得。</div>
        <div class="dialog_consult">参考答案:</div>
        <div class="dialog_conlist">
          <div class="dialog_item" v-for="(i, index) in assignmentlist" :key="index">
            {{ index + 1 }}、{{ i.answerMode === 1 ? i.answer.indexOf(';') === -1 ?  i.answer : i.answer+'(多答案，任一都对)' : i.taskName }}
            <div class="dialog_download" v-if="i.answerMode == 2" @click="down(i.answer)">
              (<span class="dialog_download1">下载</span>)
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
    <div style="width: 1200px; margin: 0 auto;">
      <startup v-if="show == 'startup'" ref="startup"></startup>
    </div>
  </div>
</template>

<script>
import { getActivityStore, copyActivity, getActivityTaskAnswers, getQRCode } from '@/api/api'
import HeaderView from '@/components/header/HeaderView.vue'
import startup from '../planningcenter/components/startup/index.vue'
export default {
  name: 'TaskView',
  components: {
    HeaderView,
    startup
  },
  computed: {
    user () {
      return this.$store.getters.user
    }
  },
  data() {
    return {
      input: '',
      show: null,
      list: [],
      xzid: '',
      page: 0,
      detailedshow: false,
      code: '',
      assignmentlist: [],
      xzdata: {}
    }
  },
  methods: {
    down (url) {
      if (url) {
        window.open(url)
      } else {
        this.$message.error('无法下载')
      }
    },
    copy () {
      this.$prompt('请输入新名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputPattern: '',
        inputErrorMessage: ''
      }).then(async ({ value }) => {
        const data = {
          adminId: this.user.adminId,
          activityName: value,
          activityId: this.xzid
        }
        const res = await copyActivity(data)
        if (res.data) {
          this.$message({
            type: 'success',
            message: '复制成功!'
          });
          this.xzid = ''
        }
      }).catch(() => {
             
      });
    },
    async select (row) {
      this.xzid = row.id
      await this.getActivityTaskAnswers()
      await this.showcode()
      this.xzdata = row
      this.detailedshow = true
    },
    async getActivityTaskAnswers () {
      const data = {
        activityId: this.xzid
      }
      const res = await getActivityTaskAnswers(data)
      this.assignmentlist = res.data
    },
    async showcode () {
      const data = {
        path: 'pages/startup/startup',
        scene: this.xzid
      }
      const res = await getQRCode(data)
      if (res.code == 0) {
        this.code = res.data
      }
    },
    addactivity (type) {
      this.show = type
      this.$nextTick(() => {
        this.$refs.startup.getstore()
        this.$refs.startup.getinit()
      })
    },
    inputsearch () {
      this.page = 1
      this.list = []
      this.getActivityPageList()
    },
    async getActivityPageList () {
      const data = {
        activityName: this.input,
        adminId: 0,
        status: 4,
        pageIndex: this.page,
        pageSize: 20
      }
      const res = await getActivityStore(data)
      res.data.list.forEach((res) => {
        if (res.label) {
          res.label = res.label.split(',')
        }
      })
      this.list = [ ...this.list,...res.data.list]
      this.xzid = ''
    },
    load () {
      this.page++
      this.getActivityPageList()
    }
  },
  mounted () {
  }
}
</script>
<style lang="scss" scoped>
@import './index.scss'
</style>
